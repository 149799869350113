/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import Api from '../../@crema/services/ApiConfig';
import {useSelector} from 'react-redux';

// redux variables
import {
  OTP_TIME,
  FETCH_ERROR,
  AMAZON_CALL_MSG,
  AMAZON_TIMEDIFF_COUNT,
  GET_DOCTOR_GANGADATA,
  ADMIN_APPROVE_SUCC,
  ADMIN_MASTER_DATA,
  VITAL_DUPLICATE_CHECK,
  GET_TELE_OPERATOR_NAME,
  GET_DIGI_OPERATOR_NAME,
  IPD_DEPT_DET,
  ADMIN_PENDING_LIST,
  SHOW_MESSAGE,
  FETCH_START,
  SUGGEST_MED,
  SUGGEST_MED_NER,
  MEDICINE_SUGGESTIONS,
  SHOWN_GEN_FIELD,
  SUGGEST_NER_MED_MESSAGE,
  ADMIN_PENDING_COUNT,
  MRD_CLIENT_LIST,
  AMAZON_RX_LINK,
  DASHBOARD_IPDPENDING_PATIENT_DATA,
  DASHBOARD_DEPARTMENT,
  PREVIOUS_ERXID,
  TELE_LIST_RESULT,
  PREVIEW_AMAZON_RX_LINK,
  CLIENT_SEARCH_LIST,
  DASHBOARD_LIST_RESULT,
  CLIENT_SEARCH_LIST_COUNT,
  TOTAL_AMAZON_COMPLETED_COUNT,
  TOTAL_DIGI_COMPLETED_COUNT,
  TOTAL_DIGI_PENDING_COUNT,
  TOTAL_DIGI_FAILURE_COUNT,
  TOTAL_DIGI_INPROGRESS_COUNT,
  TOTAL_DIGI_REJECT_COUNT,
  TOTAL_AMAZON_PENDING_COUNT,
  GETHOSPITAL_ID,
  DASHBOARD_SUCCESS_MSG,
  DIGI_PENDING_DATA,
  CLIENT_MEDIA_lIST,
  DIGI_PENDING_COUNT,
  TOTAL_AMAZON_FAILURE_COUNT,
  TOTAL_AMAZON_REJECT_COUNT,
  TOTAL_AMAZON_INPROGRESS_COUNT,
  AMAZON_FAILURE_COUNT,
  AMAZON_CONSULT_TIMING,
  AMAZON_IDLE_TIME,
  SUGGEST_MED_MESSAGE,
  SUGGEST_MED_OPTION,
  SUGGEST_PAT_MESSAGE,
  FETCH_SUCCESS,
  GET_HC_DATA,
  GENERATE_AMAZON_PDF,
  AMAZON_CALL_LOG_COUNT,
  SEND_AMAZON_SUCCESS_MSG,
  DASHBOARD_PENDING_DATA,
  DASHBOARD_PENDING_COUNT,
  DASHBOARD_PENDING_PATIENT_DATA,
  AMAZON_PENDING_PATIENT_DATA,
  AMAZON_PENDING_PATIENT_DETAILS,
  PATIENT_SUCCESS_MSG,
  ERX_SUCCESS_MSG,
  MEDICINE_SUCCESS_MSG,
  GENERICMEDICINE_SUCCESS_MSG,
  DELETE_MEDCINE_SUCCESS_MSG,
  DELETE_GENERICMEDCINE_SUCCESS_MSG,
  DASHBOARD_ERX_DATA,
  ERX_IMG_ARR,
  ERX_IMG_OCR,
  MEDICINE_REJECT_REASONS,
  CALL_ATTEMPTS,
  AMAZON_PENDING_DATA,
  AMAZON_INPROGRESSS_DATA,
  AMAZON_INPROGRESSS_COUNT,
  AMAZON_CALLBACK_COUNT,
  AMAZON_CONFAIL_DATA,
  AMAZON_CONFAIL_COUNT,
  AMAZON_CALLBACK_DATA,
  AMAZON_PENDING_COUNT,
  AMAZON_ERX_DOC_OBSV,
  AMAZON_RX_DETAILS,
  AMAZON_ERX_LAB_OBSV,
  AMAZON_ERX_PATIENT_VITAL,
  AMAZON_ERX_DOCPROCEDURE,
  AMAZON_ERX_MEDICINE_DETAIL,
  AMAZON_GRX_MEDICINE_DETAIL,
  GET_AMAZON_DOCTOR_EDITDATA,
  AMAZON_DOCTORS_SUGGESTIONS,
  AMAZON_SEARCH_CONSULTING_TIME,
  IPD_DYNFIELD_LIST,
  GET_DYNFIELD_STS,
  GET_DOCTOR_EDITDATA,
  GET_DOCTOR_SUGGESTION_HOSPITAL,
  DASHBOARD_INSERT_PRESCRIPTION,
  DASHBOARD_INPROGRESSS_DATA,
  DASHBOARD_INPROGRESSS_COUNT,
  DOCTOR_SUCCESS_MSG,
  DASHBOARD_ERX_TESTNAME,
  DOCTOR_UPDATE_MSG,
  DASHBOARD_RX_DETAILS,
  DASHBOARD_ERX_DOC_OBSV,
  DASHBOARD_ERX_LAB_OBSV,
  DASHBOARD_ERX_PATIENT_VITAL,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_ERX_MEDICINE_DETAIL,
  IPD_DISCHARGE_ADVICE,
  DASHBOARD_GRX_MEDICINE_DETAIL,
  DASHBOARD_REJECT_DATA,
  AMAZON_REJECT_COUNT,
  AMAZON_REJECT_DATA,
  DASHBOARD_REJECT_COUNT,
  REJECT_ERX_SUCCESS_MSG,
  DASHBOARD_COMPLETED_DATA,
  AMAZON_COMPLETED_COUNT,
  AMAZON_COMPLETED_DATA,
  DASHBOARD_COMPLETED_COUNT,
  DASHBOARD_CALL_COMPLETED_DATA,
  DASHBOARD_CALL_COMPLETED_COUNT,
  DASHBOARD_CALL_PENDING_DATA,
  DASHBOARD_CALL_PENDING_COUNT,
  UNIT_DATA,
  ROUTE_DATA,
  MEDICINE_CATEGORY_DATA,
  ERX_OPERATOR_NOTES,
  SET_DOCTOR_ID_NAME,
  DOCTOR_SPECIALIZATION,
  CALL_REASONS,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_QUALIFICATION,
  DEPARTMENT_MASTER,
  STATE_MASTER,
  SETTINGS,
  pulseList,
  weightList,
  bpList,
  tempList,
  spo2List,
  hemoglobinList,
  serumList,
  bloodureaList,
  tlcount,
  bloodsugarList,
  resulttitle,
  USER_PERMISSIONS,
  MEDIA_lIST,
  REJECT_REASONS,
  EXIST_COUNT,
  DOCTOR_SEARCH_ARRAY,
  DOCTOR_SEARCH_DATA,
  HOSPITAL_SEARCH_DATA,
  HOSPITAL_SEARCH_OLD_DATA,
  ONLINE_OFFLINE_STATUS,
  GENERATE_PDF,
  SEND_SUCCESS_MSG,
  SEARCH_CONSULTING_TIME,
  durationList,
  REFERRALLIST,
  DOSAGELIST,
  ADD_MEDICINE,
  APPROVE_MEDICINE_MSG,
  CALLBACK_MSG,
  INSERT_EXIST_COUNT,
  MATCHED_DOCTORS,
  DOCTOR_SELECT_DATA,
  MATCHED_DOCTORS_SUCCESS,
  GET_MASTER_LIST_FOR_VITAL,
  APPROVE_STATUS_RESULT,
  DELETE_VITAL_STATUS_RESULT,
  ERX_HTML_DATA,
  ERX_HTML_NO_DATA,
  INVESTIGATION_CATEGORY_DATA,
  REPORT_DETAILS,
  REPORT_VALIDATION,
  REPORT_ERROR,
  TOTAL_DASHBOARD_COMPLETED_COUNT,
  TOTAL_DASHBOARD_INPROGRESSS_COUNT,
  TOTAL_DASHBOARD_REJECT_COUNT,
  TOTAL_DASHBOARD_CALLCOMPLETE_COUNT,
  TOTAL_DASHBOARD_CALLBACk_COUNT,
  TOTAL_DASHBOARD_PENDING_COUNT,
  BUCKET_CAPTCHA,
  APPOINTMENT_REASON,
  APPOINTMENT_SUCCESS,
  APPOINTMENT_SUCCESS_REFERESH,
  SEARCH_PATIENT_LIST,
  SEARCH_PATIENT_LIST_COUNT,
  PATIENT_APPOINTMENT_LIST,
  PATIENT_APPOINTMENT_COUNT,
  TAG_CATEGORY_DATA,
  APPOINTMENT_PATIENTS,
  CLIENT_DETAILS,
  ERX_LIST_REPORT,
  ERX_OLD_LIST_REPORT,
  ERX_LIST_REPORT_COUNT,
  OPERATOR_LIST,
  CLIENT_LIST,
  CLIENT_DASHBOARD_DETAILS,
  CLIENT_EXCEL_LIST,
  CLIENT_BILLING_DETAILS,
  VENDOR_LIST,
  CLIENT_DASHBOARD_CLIST,
  CLIENT_ERX_LIST,
  CLIENT_ERX_LIST_COUNT,
  DOCTORS_SUGGESTIONS,
  SETTINGS_DATA,
  CLIENT_ERX_TOTAL_FEES,
  DUPLICATE_ERX_DATA,
  SUGGEST_INVESTIGATION,
  SUGGEST_INVES_MSG,
  ENTITY_MAPPING,
  SEARCH_NER_INVESTIGATION,
  SEARCH_NER_SYMPTOMS,
  SEARCH_NER_PROCEDURE,
  SEARCH_NER_DISEASES,
  SEARCH_NER_DIAGNOSIS,
  SEARCH_NER_ALLERGY,
  SUGGEST_PAT,
  SEARCH_NER_HOSPITALS,
  GET_AMAZON_DOCTOR,
  AMAZON_MED,
  AMAZON_ERX_DETAILS,
  CALLBACK_SUCCESS_MSG,
  DOCTOR_AUTOFILL,
  AMAZON_FAILURE_DATA,
  VITAL_SUGGESTIONS_MSG,
  ADMIN_PENDING_APPROVALS,
  ADMIN_PENDING_APPROVALS_LIST,
  VITAL_SUGGESTIONS,
  AUTOMARKCROP_COORDINATES,
  GET_APPROVED_VITALS,
  GET_CALL_REASONS,
  DOCTOR_NOTES_TEST_NAMES,
  HIGHPRIORITYCOUNT,
  GANGARAM_PATIENT_DATA,
  TC_DUPLICATE_MESSAGE,
  DIGITIZATION_TIMEDIFF_COUNT,
  RETRY_OCR,
  SET_DOCTOR_HOSPITAL_ID,
  GET_CONSULTANT_DOCTOR_EDITDATA,
  MATCHED_CONSULTANT_DOCTORS,
  DOCTOR_CONSULTANT_SEARCH_DATA,
  DOCTOR_CONSULTANT_SELECT_DATA,
  REMEMBER_ME_DAYS,
  GET_REGISTERED_CLIENTS,
  GET_REGISTERED_CLIENTS_DOCTORS,
  GET_VACCINATION_MASTER,
  VACCINATION_SAVE_MSG,
  VACCINATION_SAVE_DATA,
  VACCINATION_DONE_DATE,
  GET_OPERATOR_NAME,
  GET_CLIENT_DETAILS,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
// const variables
import {
  sendReview,
  backReview,
  userRoleMaster,
} from '../../shared/constants/AppConst';
import {DataBrew} from 'aws-sdk';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Dashboard.js';
const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var password = 'd6F3Efeq';
export const onGetHCData = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/dashboard/health_care')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_HC_DATA, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

//Get dashboard Pending List Data
export const onGetDashboardPendingListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };

    try {
      //Call pending list Token  - Get Token
      jwtAxios
        .post('v1/pateRxMgmt/geteRxPendingListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/pateRxMgmt/geteRxPendingList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data?.data?.body?.data || '';
                    dispatch({
                      type: DASHBOARD_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DIGITIZATION_TIMEDIFF_COUNT,
                      payload: data?.data?.body?.data?.timediffcount,
                    });
                    dispatch({
                      type: DASHBOARD_PENDING_DATA,
                      payload: getdata,
                    });
                    dispatch({
                      type: DASHBOARD_PENDING_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });

                    //Clear Erxdatas every pending list loading
                    dispatch({
                      type: DASHBOARD_RX_DETAILS,
                      payload: {},
                    });
                    dispatch({
                      type: DASHBOARD_ERX_DOC_OBSV,
                      payload: {},
                    });
                    dispatch({
                      type: DASHBOARD_ERX_LAB_OBSV,
                      payload: {},
                    });
                    dispatch({
                      type: DASHBOARD_ERX_PATIENT_VITAL,
                      payload: {},
                    });
                    dispatch({
                      type: DASHBOARD_ERX_DOCPROCEDURE,
                      payload: {},
                    });

                    dispatch({
                      type: DASHBOARD_ERX_MEDICINE_DETAIL,
                      payload: {},
                    });
                    dispatch({
                      type: DASHBOARD_GRX_MEDICINE_DETAIL,
                      payload: {},
                    });
                    dispatch({
                      type: GET_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    dispatch({
                      type: GET_CONSULTANT_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    dispatch({
                      type: DOCTORS_SUGGESTIONS,
                      payload: {},
                    });
                    dispatch({
                      type: DOCTOR_AUTOFILL,
                      payload: {},
                    });

                    dispatch({
                      type: SEARCH_CONSULTING_TIME,
                      payload: [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardPendingListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardPendingListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardPendingListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardPendingListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending List Data
export const onGetAmazonDashboardPendingListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  session_id,
  sortBy,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
      filterMobileNo,
      sortBy,
    };

    try {
      //Call pending list Token  - Get Token
      jwtAxios
        .post('v1/teleConsultant/getAmazoneRxPendingListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/teleConsultant/getAmazoneRxPendingList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data?.data?.body?.data;
                    dispatch({
                      type: TELE_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: AMAZON_PENDING_DATA,
                      payload: getdata ? getdata : [],
                    });
                    dispatch({
                      type: AMAZON_PENDING_COUNT,
                      payload: data?.data?.body?.data?.totalCount
                        ? data?.data?.body?.data?.totalCount
                        : 0,
                    });
                    console.log(
                      data?.data?.body?.data,
                      'data?.data?.body?.data',
                    );
                    dispatch({
                      type: AMAZON_TIMEDIFF_COUNT,
                      payload: data?.data?.body?.data?.timeDifference
                        ? data?.data?.body?.data?.timeDifference
                        : [],
                    });
                    //Clear Erxdatas every pending list loading
                    dispatch({
                      type: AMAZON_RX_DETAILS,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_DOC_OBSV,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_LAB_OBSV,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_PATIENT_VITAL,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_DOCPROCEDURE,
                      payload: {},
                    });

                    dispatch({
                      type: AMAZON_ERX_MEDICINE_DETAIL,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_GRX_MEDICINE_DETAIL,
                      payload: {},
                    });
                    dispatch({
                      type: GET_AMAZON_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_DOCTORS_SUGGESTIONS,
                      payload: {},
                    });

                    dispatch({
                      type: AMAZON_SEARCH_CONSULTING_TIME,
                      payload: [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardPendingListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardPendingListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardPendingListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardPendingListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join('-');
}

//Get dashboard Pending List Data
export const onGetAmazonDashboardSendFailureListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  session_id,
  retryTag,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body1 = {retryTag};
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
      filterMobileNo,
    };

    try {
      //Call pending list Token  - Get Token
      jwtAxios
        .post('v1/teleConsultant/getAmazoneRxSendFailureListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post(
                  'v1/teleConsultant/getAmazoneRxSendFailureList',
                  bodytoken,
                )
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: TELE_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: AMAZON_FAILURE_DATA,
                      payload: getdata,
                    });
                    dispatch({
                      type: AMAZON_FAILURE_COUNT,
                      payload: data.data.body.data.totalCount,
                    });

                    //Clear Erxdatas every pending list loading
                    dispatch({
                      type: AMAZON_RX_DETAILS,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_DOC_OBSV,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_LAB_OBSV,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_PATIENT_VITAL,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_ERX_DOCPROCEDURE,
                      payload: {},
                    });

                    dispatch({
                      type: AMAZON_ERX_MEDICINE_DETAIL,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_GRX_MEDICINE_DETAIL,
                      payload: {},
                    });
                    dispatch({
                      type: GET_AMAZON_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    dispatch({
                      type: AMAZON_DOCTORS_SUGGESTIONS,
                      payload: {},
                    });

                    dispatch({
                      type: AMAZON_SEARCH_CONSULTING_TIME,
                      payload: [],
                    });
                    if (body1?.retryTag == true) {
                      let passdata = {
                        userCode: bodytoken.userCode,
                        userId: '',
                        status: '2',
                        offset: 0,
                        limit: 25,
                        filtercheckInStartDate: localStorage.getItem(
                          'AmazonFilterFromDate',
                        )
                          ? localStorage.getItem('AmazonFilterFromDate')
                          : '',
                        filtercheckInEndDate: localStorage.getItem(
                          'AmazonFilterToDate',
                        )
                          ? localStorage.getItem('AmazonFilterToDate')
                          : '',
                        filterfullfillmentStartDate: localStorage.getItem(
                          'AmazonFilterFromDate',
                        )
                          ? ''
                          : formatDate(new Date()),
                        filterfullfillmentEndDate: localStorage.getItem(
                          'AmazonFilterToDate',
                        )
                          ? ''
                          : formatDate(new Date()),
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: bodytoken.session_id,
                      };
                      let passdata1 = {
                        userCode: bodytoken.userCode,
                        userId: '',
                        status: '3',
                        offset: 0,
                        limit: 25,
                        filtercheckInStartDate: localStorage.getItem(
                          'AmazonFilterFromDate',
                        )
                          ? localStorage.getItem('AmazonFilterFromDate')
                          : '',
                        filtercheckInEndDate: localStorage.getItem(
                          'AmazonFilterToDate',
                        )
                          ? localStorage.getItem('AmazonFilterToDate')
                          : '',
                        filterfullfillmentStartDate: localStorage.getItem(
                          'AmazonFilterFromDate',
                        )
                          ? ''
                          : formatDate(new Date()),
                        filterfullfillmentEndDate: localStorage.getItem(
                          'AmazonFilterToDate',
                        )
                          ? ''
                          : formatDate(new Date()),
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: bodytoken.session_id,
                      };
                      dispatch(onGetAmazonRejectListData(passdata));
                      dispatch(onGetAmazonCompeletdListData(passdata1));
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardPendingListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardPendingListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardPendingListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardPendingListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onGetPendingPatInfn = ({userId, userCode, patId, erxid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, patId, erxid};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/patientDtls/getpatientjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, userCode, patId, erxid, jwtToken};
              jwtAxios
                .post('v1/patientDtls/getpatient', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GANGARAM_PATIENT_DATA,
                      payload: data.data.body[0].gangaPatSugg,
                    });
                    const getdata = data.data.body[0];
                    dispatch({
                      type: DASHBOARD_PENDING_PATIENT_DATA,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      body.userCode,
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          body.userCode,
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onGetAmazonPendingPatInfn = ({userId, userCode, patId, erxid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, patId, erxid};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazonPatientJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, userCode, patId, erxid, jwtToken};
              jwtAxios
                .post('v1/teleConsultant/getAmazonPatient', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.patRes;
                    const getdetails = data.data.body.patDetails;
                    dispatch({
                      type: AMAZON_PENDING_PATIENT_DATA,
                      payload: getdata,
                    });
                    dispatch({
                      type: AMAZON_PENDING_PATIENT_DETAILS,
                      payload: getdetails,
                    });
                    dispatch({
                      type: AMAZON_ERX_DETAILS,
                      payload: data.data.body.erxdetails,
                    });
                    let urlList = data.data.body.erxlink;
                    for (let i = 0; i < urlList.length; i++) {
                      urlList[i].rownumber = i + 1; // Assuming rownumber starts from 1
                    }
                    dispatch({
                      type: AMAZON_RX_LINK,
                      payload: urlList,
                    });
                    // dispatch({
                    //   type: PREVIEW_AMAZON_RX_LINK,
                    //   payload: data.data.body.erxlink[0],
                    // });

                    dispatch({
                      type: AMAZON_CALL_LOG_COUNT,
                      payload: data.data.body.callLogCount,
                    });
                    dispatch({
                      type: AMAZON_CONSULT_TIMING,
                      payload: data.data.body.consultation_timing,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      body.userCode,
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          body.userCode,
          err,
        ),
      );
    }
  };
};

// Update Patient details
export const onUpdatePatientDetails = (value, doctotDetails) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    let drdet = doctotDetails;
    try {
      jwtAxios
        .post('v1/patientDtls/updatejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // update patient info
            jwtAxios
              .post('v1/patientDtls/update', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  if (data.data.hasOwnProperty.call(data.data, 'body')) {
                    sessionStorage.setItem(
                      'patID',
                      data?.data?.body?.uniquePatId,
                    );
                  }
                  sessionStorage.setItem('patInfosave', true);
                  let newDrDet =
                    data?.data?.body?.docDetails?.[0] &&
                    data?.data?.body?.docDetails?.length > 0
                      ? data?.data?.body?.docDetails?.[0]
                      : '';
                  let passDrDet = {
                    ...newDrDet,
                  };

                  dispatch({
                    type: GET_DOCTOR_GANGADATA,
                    payload: passDrDet,
                  });
                  let hospitalId = sessionStorage.getItem('hospitalId');
                  sessionStorage.setItem('patSave', true);
                  // dispatch(
                  //   onGetDoctorInfo({
                  //     userId: value.userId,
                  //     userCode: value.userCode,
                  //     erxId: value.erxid,
                  //     hospitalId: hospitalId || '',
                  //   }),
                  // );
                  dispatch({
                    type: PATIENT_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                  dispatch(
                    onGetPendingPatInfn({
                      userId: value?.userId,
                      patId: data?.data?.body?.patientId,
                      userCode: value?.userCode,
                      erxid: value?.erxid,
                    }),
                  );
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdatePatientDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdatePatientDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdatePatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdatePatientDetails',
          '0',
          err,
        ),
      );
    }
  };
};
// Update Patient details
export const onUpdateIPDPatientDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/updateIPDjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // update patient info
            jwtAxios
              .post('v1/ipdPres/updateIPD', value)
              .then((data) => {
                if (data.data.status === 200) {
                  // dispatch({type: FETCH_SUCCESS});
                  if (data.data.hasOwnProperty.call(data.data, 'body')) {
                    sessionStorage.setItem(
                      'patID',
                      data?.data?.body?.uniquePatId,
                    );
                  }
                  let hospitalId = sessionStorage.getItem('hospitalId');
                  sessionStorage.setItem('patSave', true);
                  dispatch(
                    onGetIPDDoctorInfo({
                      userId: value.userId,
                      userCode: value.userCode,
                      erxId: value.erxid,
                      hospitalId: hospitalId || '',
                    }),
                  );
                  dispatch({
                    type: PATIENT_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                  dispatch(
                    onGetIPDPendingPatInfn({
                      userId: value?.userId,
                      patId: data?.data?.body?.patientId,
                      userCode: value?.userCode,
                      erxid: value?.erxid,
                    }),
                  );
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdatePatientDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdatePatientDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdatePatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdatePatientDetails',
          '0',
          err,
        ),
      );
    }
  };
};
export const callAmazonPatient = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let passparams = {
        erxId: value.erxId,
        userCode: value.userCode,
        status: '1',
      };
      jwtAxios
        .post(
          'v1/teleConsultant/teleConsultationCheckDuplicateEntries',
          passparams,
        )
        .then((data) => {
          if (data?.data?.body?.duplicate_entry == 'false') {
            jwtAxios
              .post('v1/teleConsultant/callReasons', value)
              .then((data) => {
                try {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    {
                      dispatch({
                        type: AMAZON_CALL_MSG,
                        payload: data.data.body.msg,
                      });
                      dispatch({
                        type: AMAZON_CALL_LOG_COUNT,
                        payload: data.data.body.callLogCount,
                      });
                    }
                  }
                } catch (err) {
                  dispatch(fetchError(err?.response?.message));
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'callAmazonPatient',
                      '0',
                      err,
                    ),
                  );
                }
              });
          } else {
            dispatch({type: FETCH_ERROR, payload: TC_DUPLICATE_MESSAGE});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'callAmazonPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'callAmazonPatient',
          '0',
          err,
        ),
      );
    }
  };
};
// Update Patient details
export const onUpdateAmazonPatientDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      let passparams = {
        erxId: value['erxid'],
        userCode: value['userCode'],
        status: '1',
      };
      jwtAxios
        .post(
          'v1/teleConsultant/teleConsultationCheckDuplicateEntries',
          passparams,
        )
        .then((data) => {
          if (data?.data?.body?.duplicate_entry == 'false') {
            jwtAxios
              .post('v1/teleConsultant/amazonUpdatepatientjwt', value)
              .then((data) => {
                try {
                  value['jwtToken'] = data.data.body['token'];
                  // update patient info
                  jwtAxios
                    .post('v1/teleConsultant/amazonUpdatePatient', value)
                    .then((data) => {
                      if (data.data.status === 200) {
                        dispatch({type: FETCH_SUCCESS});
                        dispatch({
                          type: PATIENT_SUCCESS_MSG,
                          payload: data.data.message,
                        });
                        if (data?.data?.body?.medData?.length > 0) {
                          dispatch({
                            type: AMAZON_MED,
                            payload:
                              data?.data?.body?.medData &&
                              data?.data?.body?.medData?.length > 0
                                ? data?.data?.body?.medData
                                : [],
                          });
                        }
                        let passdata = {
                          userCode: value.userCode,
                          erxid: value.erxid,
                          patId: value.patId,
                        };
                        dispatch(onGetAmazonPendingPatInfn(passdata));
                        dispatch({
                          type: SHOW_MESSAGE,
                          payload: data.data.message,
                        });
                      } else {
                        dispatch({
                          type: FETCH_ERROR,
                          payload: (
                            <IntlMessages id='message.somethingWentWrong' />
                          ),
                        });
                      }
                    })
                    .catch((error) => {
                      dispatch({type: FETCH_ERROR, payload: error.message});
                      dispatch(
                        onWriteCloudWatchLog(
                          streamname,
                          filename,
                          'onUpdateAmazonPatientDetails',
                          '0',
                          error,
                        ),
                      );
                    });
                } catch (err) {
                  dispatch(fetchError(err.response.message));
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onUpdateAmazonPatientDetails',
                      '0',
                      err,
                    ),
                  );
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateAmazonPatientDetails',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            dispatch({type: FETCH_ERROR, payload: TC_DUPLICATE_MESSAGE});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateAmazonPatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateAmazonPatientDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Update eRx details
export const onUpdateeRxDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/updateeRxDtlsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/updateeRxDtls', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: ERX_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                  dispatch(
                    {
                      type: VACCINATION_SAVE_MSG,
                      payload: '',
                    },
                    {
                      type: VACCINATION_SAVE_DATA,
                      payload: '',
                    },
                    {
                      type: VACCINATION_DONE_DATE,
                      payload: '',
                    },
                  );

                  dispatch(
                    saveMarkedVaccine({
                      userCode: value?.userCode,
                      erxId: value?.erxReqId,
                      ageLabel: '',
                      prescriptionDate: value?.erxDate,
                      process: 'load',
                    }),
                  );
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateeRxDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateeRxDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateeRxDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateeRxDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Update eRx details
export const onUpdateIPDeRxDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/updateeIPDRxDtlsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/updateIPDeRxDtls', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: ERX_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateIPDeRxDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateIPDeRxDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateIPDeRxDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateIPDeRxDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// send to Review
export const sendToReviews = ({
  userCode,
  erxId,
  userId,
  status,
  roleId,
  erxStatus,
  editPrescription,
  session_id,
}) => {
  return (dispatch) => {
    //Call SendToReview Token  - Get Token
    const value = {
      userCode,
      erxId,
      userId,
      status,
      erxStatus,
      editPrescription,
      session_id,
    };
    try {
      jwtAxios
        .post('v1/pateRxMgmt/sendToReviewjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/sendtoReview', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  let message = data.data.message;
                  if (status === sendReview) {
                    if (roleId === userRoleMaster.L1Operator) {
                      message =
                        'The prescription has been successfully forwarded to L2 operator';
                    } else if (roleId === userRoleMaster.L2Operator) {
                      message =
                        'The prescription has been successfully forwarded to L3 operator';
                    }
                  } else if (status === backReview) {
                    if (roleId === userRoleMaster.L3Operator) {
                      //l3 Operator
                      message =
                        'The prescription has been successfully sent back to L2 operator';
                    } else if (roleId === userRoleMaster.L2Operator) {
                      //l2 Operator
                      message =
                        'The prescription has been successfully sent back to L1 operator';
                    }
                  }
                  dispatch({
                    type: SEND_SUCCESS_MSG,
                    payload: message,
                  });

                  //Get pending list data from api
                  if (userCode != null) {
                    dispatch({
                      type: DASHBOARD_PENDING_DATA,
                      payload: [],
                    });
                    dispatch({
                      type: DASHBOARD_PENDING_COUNT,
                      payload: 0,
                    });
                    dispatch(
                      onGetDashboardPendingListData({
                        userCode: userCode,
                        userId: '',
                        status: '0',
                        offset: 0,
                        limit: 10,
                        filtercheckInStartDate: '',
                        filtercheckInEndDate: '',
                        filterfullfillmentStartDate: '',
                        filterfullfillmentEndDate: '',
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: session_id,
                      }),
                    );
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'sendToReviews',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendToReviews',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendToReviews',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'sendToReviews', '0', err),
      );
    }
  };
};

// Add eRx Medicine details
export const onAddMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/pateRxMgmt/insertMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: MEDICINE_SUCCESS_MSG,
                      payload: data.data.message,
                    });
                    dispatch(
                      onGeteRxMedicines({
                        userId: value.userId,
                        erxReqId: value.erxReqId.toString(),
                        userCode: value.userCode,
                      }),
                    );
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onAddMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onAddMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Add IPD eRx Medicine details
export const onAddIPDMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertIPDMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/pateRxMgmt/insertIPDMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: MEDICINE_SUCCESS_MSG,
                      payload: data.data.message,
                    });
                    dispatch(
                      onGetIPDeRxMedicines({
                        userId: value.userId,
                        erxReqId: value.erxReqId.toString(),
                        userCode: value.userCode,
                      }),
                    );
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onAddIPDMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onAddIPDMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddIPDMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Add eRx Medicine details
export const onAddGenericMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertGenericMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/pateRxMgmt/insertGenericMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: GENERICMEDICINE_SUCCESS_MSG,
                      payload: data.data.message,
                    });
                    dispatch(
                      onGeteRxMedicines({
                        userId: value.userId,
                        erxReqId: value.erxReqId.toString(),
                        userCode: value.userCode,
                      }),
                    );
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onAddGenericMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onAddGenericMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddGenericMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Add eRx procedure details
export const onAddProcedureDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertProcedureData', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddProcedureDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddProcedureDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Medicine details
export const onDeleteMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/pateRxMgmt/deleteMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: DELETE_MEDCINE_SUCCESS_MSG,
                      payload: data.data.message,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onDeleteMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onDeleteMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Medicine details
export const onDeleteAmazonMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteAmazonMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/pateRxMgmt/deleteAmazonMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: DELETE_MEDCINE_SUCCESS_MSG,
                      payload: data.data.message,
                    });
                    let passdata = {
                      userId: value.userId,
                      erxReqId: value.erxId,
                      userCode: value.userCode,
                    };
                    dispatch(onGetAmazonMedicines(passdata));
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onDeleteMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onDeleteMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Generic Medicine details
export const onDeleteGenericMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteGenericMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/pateRxMgmt/deleteGenericMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: DELETE_GENERICMEDCINE_SUCCESS_MSG,
                      payload: data.data.message,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onDeleteGenericMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onDeleteGenericMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteGenericMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Procedure details
export const onDeleteProcedureDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteProcedureData', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onDeleteProcedureDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteProcedureDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Add Reject eRx Medicine details
export const onRejecteRx = (value) => {
  // const history = useHistory();
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/rejecteRxjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/rejecteRx', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  //Get pending list data from api
                  if (value.userCode != null) {
                    dispatch({
                      type: DASHBOARD_PENDING_DATA,
                      payload: [],
                    });
                    dispatch({
                      type: DASHBOARD_PENDING_COUNT,
                      payload: 0,
                    });
                    dispatch(
                      onGetDashboardPendingListData({
                        userCode: value.userCode,
                        userId: '',
                        status: '0',
                        offset: 0,
                        limit: 10,
                        filtercheckInStartDate: '',
                        filtercheckInEndDate: '',
                        filterfullfillmentStartDate: '',
                        filterfullfillmentEndDate: '',
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: value.session_id,
                      }),
                    );
                    const filteroptions = localStorage.getItem('rejectedFilter')
                      ? JSON.parse(localStorage.getItem('rejectedFilter'))
                      : '';
                    const {
                      filterfullfillmentStartDate,
                      filterfullfillmentEndDate,
                    } = filteroptions;
                    let filterset = localStorage.getItem('filterSet');
                    // let fullfillmentStartDate =
                    //   alllist === 'true' ? formatDate(new Date()) : formatDate(new Date());
                    // let fullfillmentEndDate =
                    //   alllist === 'true' ? formatDate(new Date()) : formatDate(new Date());

                    dispatch(
                      onGetDashboardRejectListData({
                        userCode: value.userCode,
                        userId: '',
                        status: '2',
                        offset: 0,
                        limit: 10,
                        filtercheckInStartDate: '',
                        filtercheckInEndDate: '',
                        filterfullfillmentStartDate: filterset
                          ? filterfullfillmentStartDate
                            ? filterfullfillmentStartDate
                            : ''
                          : '',
                        filterfullfillmentEndDate: filterset
                          ? filterfullfillmentEndDate
                            ? filterfullfillmentEndDate
                            : ''
                          : '',
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: value.session_id,
                      }),
                    );
                  }
                  dispatch({
                    type: REJECT_ERX_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onRejecteRx',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onRejecteRx',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onRejecteRx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onRejecteRx', '0', err),
      );
    }
  };
};

// Add Reject eRx Medicine details
export const onRejectIPDeRx = (value) => {
  // const history = useHistory();
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/rejectIPDeRxjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/rejectIPDeRx', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  //Get pending list data from api
                  if (value.userCode != null) {
                    dispatch({
                      type: DASHBOARD_PENDING_DATA,
                      payload: [],
                    });
                    dispatch({
                      type: DASHBOARD_PENDING_COUNT,
                      payload: 0,
                    });
                    dispatch(
                      onGetDashboardPendingListData({
                        userCode: value.userCode,
                        userId: '',
                        status: '0',
                        offset: 0,
                        limit: 10,
                        filtercheckInStartDate: '',
                        filtercheckInEndDate: '',
                        filterfullfillmentStartDate: '',
                        filterfullfillmentEndDate: '',
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: value.session_id,
                      }),
                    );
                    const filteroptions = localStorage.getItem('rejectedFilter')
                      ? JSON.parse(localStorage.getItem('rejectedFilter'))
                      : '';
                    const {
                      filterfullfillmentStartDate,
                      filterfullfillmentEndDate,
                    } = filteroptions;
                    let filterset = localStorage.getItem('filterSet');
                    // let fullfillmentStartDate =
                    //   alllist === 'true' ? formatDate(new Date()) : formatDate(new Date());
                    // let fullfillmentEndDate =
                    //   alllist === 'true' ? formatDate(new Date()) : formatDate(new Date());

                    dispatch(
                      onGetDashboardRejectListData({
                        userCode: value.userCode,
                        userId: '',
                        status: '2',
                        offset: 0,
                        limit: 10,
                        filtercheckInStartDate: '',
                        filtercheckInEndDate: '',
                        filterfullfillmentStartDate: filterset
                          ? filterfullfillmentStartDate
                            ? filterfullfillmentStartDate
                            : ''
                          : '',
                        filterfullfillmentEndDate: filterset
                          ? filterfullfillmentEndDate
                            ? filterfullfillmentEndDate
                            : ''
                          : '',
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: value.session_id,
                      }),
                    );
                  }
                  dispatch({
                    type: REJECT_ERX_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onRejecteRx',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onRejecteRx',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onRejecteRx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onRejecteRx', '0', err),
      );
    }
  };
};

// Get Unit data
export const onGetUnits = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getMargUomjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getMargUom', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = data.data.body.map((e, i) => {
                    return {
                      title: e.unit,
                    };
                  });
                  dispatch({
                    type: UNIT_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetUnits',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetUnits',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUnits',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetUnits', '0', err),
      );
    }
  };
};

// Get Routes data
export const onGetRoutes = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/searchRoutejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/searchRoute', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = data.data.body.map((e, i) => {
                    return {
                      title: e.description,
                    };
                  });
                  dispatch({
                    type: ROUTE_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetRoutes',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetRoutes',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetRoutes',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetRoutes', '0', err),
      );
    }
  };
};

// Get Routes data
export const onGetMedicineCategory = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/teleConsultant/searchMedicineCategoryjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/searchMedicineCategory', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = data.data.body.map((e, i) => {
                    return {
                      title: e.description,
                      id: e.id,
                      calculation: e.prescribedQuantityCalculation,
                    };
                  });
                  dispatch({
                    type: MEDICINE_CATEGORY_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetMedicineCategory',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetMedicineCategory',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetMedicineCategory',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetMedicineCategory',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Routes data
export const onGetMedicineRejectReasons = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/teleConsultant/searchMedicineRejectReasonsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/searchMedicineRejectReasons', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = data.data.body.map((e, i) => {
                    return {
                      reason: e.reason,
                      reasonId: e.reasonId,
                      userReasons: e.userReasons,
                    };
                  });
                  dispatch({
                    type: MEDICINE_REJECT_REASONS,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetMedicineCategory',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetMedicineCategory',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetMedicineCategory',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetMedicineCategory',
          '0',
          err,
        ),
      );
    }
  };
};

//get erxNotes
export const onGeteRxNotes = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getOperatorNotesjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getOperatorNotes', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  const getdata = data.data.body;
                  dispatch({
                    type: ERX_OPERATOR_NOTES,
                    payload: getdata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeteRxNotes',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxNotes',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxNotes',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxNotes', '0', err),
      );
    }
  };
};

//get erxNotes
export const onGeteRxCallReasons = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/teleConsultant/getCallReasons', value)
        .then((data) => {
          try {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: CALL_ATTEMPTS,
              payload: data.data.body,
            });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxCallReasons',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxCallReasons',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGeteRxCallReasons',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending erx information
export const onGeteRxInfn = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};

              jwtAxios
                .post('v1/pateRxMgmt/geteRxDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: DASHBOARD_ERX_DATA,
                      payload: getdata,
                    });
                    //Get Erx image and OCr data
                    if (getdata) {
                      if (getdata.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata.erxImageOCR[i].erxUrl,
                            ocrdata: getdata.erxImageOCR[i].ocrData,
                            extension: getdata.erxImageOCR[i].urlExtension,
                            rownumber: getdata.erxImageOCR[i].rownumber,
                            nerresult: getdata.erxImageOCR[i].NERresults,
                            ocrAttempt: getdata.erxImageOCR[i].ocrAttempt,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR,
                          payload: imgocr,
                        });
                      }
                    }
                    dispatch({
                      type: GET_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    dispatch({
                      type: DOCTORS_SUGGESTIONS,
                      payload: {},
                    });
                    dispatch({
                      type: DOCTOR_AUTOFILL,
                      payload: {},
                    });

                    // dispatch({
                    //   type: SEARCH_CONSULTING_TIME,
                    //   payload: [],
                    // });

                    if (getdata.rxdtls.length > 0) {
                      dispatch({
                        type: DASHBOARD_RX_DETAILS,
                        payload: getdata.rxdtls[0],
                      });
                      dispatch({
                        type: SET_DOCTOR_ID_NAME,
                        payload: {
                          DocID: getdata.rxdtls[0].doctorId,
                          DocName: getdata.rxdtls[0].docName,
                          RegisterNo: getdata.rxdtls[0].registerno,
                          EmailId: getdata.rxdtls[0].email,
                          uniqueDocID: getdata.rxdtls[0].uniqueDocID,
                          docDept: getdata?.gangaDocDept
                            ? getdata?.gangaDocDept
                            : '',
                        },
                      });
                    }

                    if (getdata.docobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOC_OBSV,
                        payload: getdata.docobsv[0],
                      });
                    }
                    if (getdata.labobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_LAB_OBSV,
                        payload: getdata.labobsv[0],
                      });
                    }
                    if (getdata?.testNameList?.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_TESTNAME,
                        payload: getdata.testNameList,
                      });
                    } else {
                      dispatch({
                        type: DASHBOARD_ERX_TESTNAME,
                        payload: [],
                      });
                    }

                    if (getdata.patientvital.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_PATIENT_VITAL,
                        payload: getdata.patientvital[0],
                      });
                    }

                    if (getdata.docprocedure.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOCPROCEDURE,
                        payload: getdata.docprocedure,
                      });
                    }
                    if (getdata.medicinedtls.length > 0) {
                      var arraydata = getdata.medicinedtls.map((e, i) => {
                        return {
                          saltName: e.saltName,
                          medName: e.medName,
                          mn: e.mn,
                          af: e.an,
                          en: e.en,
                          nt: e.nt,
                          unit: e.uomId,
                          unitcode: e.uomId,
                          quantity: e.dosage,
                          refferal: e.referral,
                          refferalHindi: e.referralHindi,
                          route: e.route,
                          advice: e.advice,
                          adviceHindi: e.adviceHindi,
                          duration: e.duration,
                          durationValue: e.durationValue,
                          frequency: e.frequency,
                          seqno: e.txnNo,
                          price: e.price,
                          status: e.status,
                          mCode: e.mCode,
                          company: e.company,
                          category: e.category,
                          dosage: e.dosage,
                          qty: e.qty,
                          strength1: e.strength1,
                          strength2: e.strength2,
                          type: e.type,
                          hindiname: e.hindiname,
                          strength: e.strength,
                          margqty: e.margqty,
                          newmrp: e.mrp,
                          reminder: e.reminder ? e.reminder : false,
                          rx_start_date: e.rx_start_date,
                          rx_end_date: e.rx_end_date,
                        };
                      });
                      dispatch({
                        type: DASHBOARD_ERX_MEDICINE_DETAIL,
                        payload: arraydata,
                      });
                    }

                    if (getdata.genericmedicinedtls.length > 0) {
                      var arraydatageneric = getdata.genericmedicinedtls.map(
                        (e, i) => {
                          return {
                            saltName: e.saltName,
                            medName: e.medName,
                            mn: e.mn,
                            af: e.an,
                            en: e.en,
                            nt: e.nt,
                            unit: e.uomId,
                            unitcode: e.uomId,
                            quantity: e.dosage,
                            refferal: e.referral,
                            refferalHindi: e.referralHindi,
                            route: e.route,
                            advice: e.advice,
                            adviceHindi: e.adviceHindi,
                            duration: e.duration,
                            durationValue: e.durationValue,
                            frequency: e.frequency,
                            seqno: e.txnNo,
                            price: e.price,
                            status: e.status,
                            mCode: e.mCode,
                            company: e.company,
                            category: e.category,
                            dosage: e.dosage,
                            qty: e.qty,
                            strength1: e.strength1,
                            strength2: e.strength2,
                            type: e.type,
                            hindiname: e.hindiname,
                            strength: e.strength,
                            margqty: e.margqty,
                            newmrp: e.mrp,
                          };
                        },
                      );
                      dispatch({
                        type: DASHBOARD_GRX_MEDICINE_DETAIL,
                        payload: arraydatageneric,
                      });
                    }
                    dispatch({
                      type: CLIENT_DETAILS,
                      payload: data.data.body.clientDetails,
                    });

                    if (getdata.rxdtls.length > 0) {
                      var patientId = getdata.rxdtls[0].patientId;
                      var hospitalId = getdata.rxdtls[0].hospitalId
                        ? getdata.rxdtls[0].hospitalId.toString()
                        : '';
                      sessionStorage.setItem('hospitalId', hospitalId);
                      dispatch(
                        onGetDoctorInfo({
                          userId: body.userId,
                          erxId: body.erxReqId,
                          userCode: body.userCode,
                          hospitalId: hospitalId || '',
                        }),
                      );
                      // }
                      dispatch(
                        onGetPendingPatInfn({
                          userId: body.userId,
                          patId: patientId,
                          userCode: body.userCode,
                          erxid: body.erxReqId,
                        }),
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGeteRxInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGeteRxInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxInfn', '0', err),
      );
    }
  };
};

//Get dashboard Pending erx information
export const onGetIPDeRxInfn = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/getIPDeRxDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};

              jwtAxios
                .post('v1/ipdPres/getIPDeRxDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: DASHBOARD_ERX_DATA,
                      payload: getdata,
                    });
                    //Get Erx image and OCr data
                    if (getdata) {
                      if (getdata.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata.erxImageOCR[i].erxUrl,
                            ocrdata: getdata.erxImageOCR[i].ocrData,
                            extension: getdata.erxImageOCR[i].urlExtension,
                            rownumber: getdata.erxImageOCR[i].rownumber,
                            nerresult: getdata.erxImageOCR[i].NERresults,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR,
                          payload: imgocr,
                        });
                      }
                    }
                    dispatch({
                      type: GET_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    dispatch({
                      type: DOCTORS_SUGGESTIONS,
                      payload: {},
                    });
                    dispatch({
                      type: DOCTOR_AUTOFILL,
                      payload: {},
                    });

                    // dispatch({
                    //   type: SEARCH_CONSULTING_TIME,
                    //   payload: [],
                    // });
                    if (getdata.rxdtls.length > 0) {
                      let passData = {
                        ...getdata.rxdtls[0],
                        ...getdata?.newVitalList,
                      };
                      dispatch({
                        type: DASHBOARD_RX_DETAILS,
                        payload: passData,
                      });
                      dispatch({
                        type: SET_DOCTOR_ID_NAME,
                        payload: {
                          DocID: getdata.rxdtls[0].doctorId,
                          DocName: getdata.rxdtls[0].docName,
                          RegisterNo: getdata.rxdtls[0].registerno,
                          EmailId: getdata.rxdtls[0].email,
                          uniqueDocID: getdata.rxdtls[0].uniqueDocID,
                        },
                      });
                    }

                    if (getdata.docobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOC_OBSV,
                        payload: getdata.docobsv[0],
                      });
                    }
                    if (getdata.labobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_LAB_OBSV,
                        payload: getdata.labobsv[0],
                      });
                    }
                    if (getdata?.testNameList?.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_TESTNAME,
                        payload: getdata.testNameList,
                      });
                    } else {
                      dispatch({
                        type: DASHBOARD_ERX_TESTNAME,
                        payload: [],
                      });
                    }
                    if (getdata.patientvital.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_PATIENT_VITAL,
                        payload: getdata.patientvital[0],
                      });
                    }

                    if (getdata.docprocedure.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOCPROCEDURE,
                        payload: getdata.docprocedure,
                      });
                    }
                    if (getdata.medicinedtls.length > 0) {
                      var arraydata = getdata.medicinedtls.map((e, i) => {
                        return {
                          saltName: e.saltName,
                          medName: e.medName,
                          mn: e.mn,
                          af: e.an,
                          en: e.en,
                          nt: e.nt,
                          unit: e.uomId,
                          unitcode: e.uomId,
                          quantity: e.dosage,
                          refferal: e.referral,
                          refferalHindi: e.referralHindi,
                          route: e.route,
                          advice: e.advice,
                          adviceHindi: e.adviceHindi,
                          duration: e.duration,
                          durationValue: e.durationValue,
                          frequency: e.frequency,
                          seqno: e.txnNo,
                          price: e.price,
                          status: e.status,
                          mCode: e.mCode,
                          company: e.company,
                          category: e.category,
                          dosage: e.dosage,
                          qty: e.qty,
                          strength1: e.strength1,
                          strength2: e.strength2,
                          type: e.type,
                          hindiname: e.hindiname,
                          strength: e.strength,
                          margqty: e.margqty,
                          newmrp: e.mrp,
                          reminder: e.reminder ? e.reminder : false,
                          rx_start_date: e.rx_start_date,
                          rx_end_date: e.rx_end_date,
                        };
                      });
                      dispatch({
                        type: DASHBOARD_ERX_MEDICINE_DETAIL,
                        payload: arraydata,
                      });
                    }
                    dispatch({
                      type: IPD_DISCHARGE_ADVICE,
                      payload: getdata?.dischargeAdvice,
                    });
                    dispatch({
                      type: CLIENT_DETAILS,
                      payload: data.data.body.clientDetails,
                    });

                    if (getdata.rxdtls.length > 0) {
                      var patientId = getdata.rxdtls[0].patientId;
                      var hospitalId = getdata.rxdtls[0].hospitalId
                        ? getdata.rxdtls[0].hospitalId.toString()
                        : '';
                      sessionStorage.setItem('hospitalId', hospitalId);
                      dispatch({
                        type: SET_DOCTOR_HOSPITAL_ID,
                        payload: hospitalId,
                      });
                      dispatch(
                        onGetIPDDoctorInfo({
                          userId: body.userId,
                          erxId: body.erxReqId,
                          userCode: body.userCode,
                          hospitalId: hospitalId || '',
                        }),
                      );
                      // }
                      dispatch(
                        onGetIPDPendingPatInfn({
                          userId: body.userId,
                          patId: patientId,
                          userCode: body.userCode,
                          erxid: body.erxReqId,
                        }),
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetIPDeRxInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetIPDeRxInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetIPDeRxInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetIPDeRxInfn', '0', err),
      );
    }
  };
};

export const onGetStatemaster = (body) => {
  return (dispatch) => {
    jwtAxios
      .post('v1/pateRxMgmt/mastersjwt', body)
      .then((data) => {
        body['jwtToken'] = data.data.body['token'];
        jwtAxios
          .post('v1/pateRxMgmt/masters', body)
          .then((data) => {
            dispatch({
              type: STATE_MASTER,
              payload: data.data.body.stateList,
            });
            dispatch({
              type: DOCTOR_QUALIFICATION,
              payload: data.data.body.quaList,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetStatemaster',
                '0',
                error,
              ),
            );
          });
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetStatemaster',
            '0',
            error,
          ),
        );
      });
  };
};

//specialization and doctor specialization event
export const onGetSpecialization = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      var value = body;
      jwtAxios
        .post('v1/doctorDtls/specializationjwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/doctorDtls/specialization', body)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: DOCTOR_SPECIALIZATION,
                    payload: data.data.body.specialization,
                  });
                  dispatch({
                    type: DOCTOR_SUBSPECIALIZATION,
                    payload: data.data.body.subspecialization,
                  });

                  jwtAxios
                    .post('v1/pateRxMgmt/mastersjwt', value)
                    .then((data) => {
                      value['jwtToken'] = data.data.body['token'];
                      jwtAxios
                        .post('v1/pateRxMgmt/masters', value)
                        .then((data) => {
                          dispatch({
                            type: DEPARTMENT_MASTER,
                            payload: data.data.body.deptList,
                          });

                          dispatch({
                            type: DOCTOR_QUALIFICATION,
                            payload: data.data.body.quaList,
                          });
                          dispatch({
                            type: STATE_MASTER,
                            payload: data.data.body.stateList,
                          });
                          dispatch({
                            type: APPOINTMENT_REASON,
                            payload: data.data.body.appointment,
                          });
                          dispatch({
                            type: SETTINGS,
                            payload: data.data.body.settingList,
                          });
                          dispatch({
                            type: pulseList,
                            payload: data.data.body.pulseList,
                          });
                          dispatch({
                            type: weightList,
                            payload: data.data.body.weightList,
                          });
                          dispatch({
                            type: durationList,
                            payload: data.data.body.durationList,
                          });
                          dispatch({
                            type: REFERRALLIST,
                            payload: data.data.body.referralList,
                          });
                          dispatch({
                            type: DOSAGELIST,
                            payload: data.data.body.dosagesList,
                          });
                          dispatch({
                            type: bpList,
                            payload: data.data.body.bpList,
                          });
                          dispatch({
                            type: tempList,
                            payload: data.data.body.tempList,
                          });
                          dispatch({
                            type: spo2List,
                            payload: data.data.body.spo2List,
                          });
                          dispatch({
                            type: hemoglobinList,
                            payload: data.data.body.hemoglobinList,
                          });
                          dispatch({
                            type: serumList,
                            payload: data.data.body.serumList,
                          });
                          dispatch({
                            type: bloodureaList,
                            payload: data.data.body.bloodureaList,
                          });
                          dispatch({
                            type: tlcount,
                            payload: data.data.body.tlcount,
                          });
                          dispatch({
                            type: bloodsugarList,
                            payload: data.data.body.bloodsugarList,
                          });
                          dispatch({
                            type: resulttitle,
                            payload: data.data.body.resulttitle,
                          });

                          dispatch({
                            type: USER_PERMISSIONS,
                            payload:
                              data.data.body.userPermissions &&
                              data.data.body.userPermissions[0] &&
                              data.data.body.userPermissions[0].permission &&
                              data.data.body.userPermissions[0].permission
                                .permission
                                ? data.data.body.userPermissions[0].permission
                                    .permission
                                : {},
                          });
                          dispatch({
                            type: MEDIA_lIST,
                            payload: data.data.body.mediaList,
                          });
                          dispatch({
                            type: REJECT_REASONS,
                            payload: data.data.body.rejectReasons,
                          });
                          dispatch({
                            type: APPOINTMENT_PATIENTS,
                            payload: data.data.body.appointmentPatients,
                          });
                          dispatch({
                            type: CLIENT_LIST,
                            payload: data.data.body.clientList,
                          });
                          dispatch({
                            type: OPERATOR_LIST,
                            payload: data.data.body.operatorList,
                          });
                          dispatch({
                            type: VENDOR_LIST,
                            payload: data.data.body.clientDashboardList,
                          });
                          dispatch({
                            type: CLIENT_DASHBOARD_CLIST,
                            payload: data.data.body.clientDashboardList,
                          });
                          dispatch({
                            type: MRD_CLIENT_LIST,
                            payload: data.data.body?.mrdClientList
                              ? data.data.body?.mrdClientList
                              : [],
                          });
                          dispatch({
                            type: SETTINGS_DATA,
                            payload: data.data.body.settingData,
                          });
                        })
                        .catch((error) => {
                          dispatch({type: FETCH_ERROR, payload: error.message});
                        });
                    })
                    .catch((error) => {
                      dispatch({type: FETCH_ERROR, payload: error.message});
                    });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSpecialization',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSpecialization',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSpecialization',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSpecialization',
          '0',
          err,
        ),
      );
    }
  };
};

//specialization and doctor specialization event
export const onGetCallReasons = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      var value = body;
      jwtAxios
        .post('v1/teleConsultant/callAttemptsjwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/callAttempts', body)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: CALL_REASONS,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSpecialization',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSpecialization',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSpecialization',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSpecialization',
          '0',
          err,
        ),
      );
    }
  };
};

// doctor tab seacrh doctor using mobile No/register
export const onGetSearchArray = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/doctorDtls/searchDoctorRegisterJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorDtls/searchDoctorRegister', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: DOCTOR_SEARCH_ARRAY,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchArray',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchArray',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchArray',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchArray',
          '0',
          err,
        ),
      );
    }
  };
};

//search match doctor detail,s

// doctor tab seacrh doctor using mobile No/register
export const onGetSearchDoctor = (value, hospitalId, consultflag) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      // get jwt token based on input

      jwtAxios
        .post('v1/doctorDtls/searchDoctorJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorDtls/searchDoctor', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});

                if (consultflag == 'consultant') {
                  dispatch({
                    type: DOCTOR_CONSULTANT_SEARCH_DATA,
                    payload: data.data.body,
                  });
                }

                if (consultflag !== 'consultant') {
                  dispatch({
                    type: DOCTOR_SEARCH_DATA,
                    payload: data.data.body,
                  });
                  dispatch({
                    type: GET_DOCTOR_SUGGESTION_HOSPITAL,
                    payload: data?.data?.body?.hospitalData || [],
                  });
                  if (
                    data?.data?.body?.hospitalData &&
                    data.data.body.hospitalData.length > 0 &&
                    data.data.body.hospitalData.length == 1
                  ) {
                    dispatch({
                      type: HOSPITAL_SEARCH_DATA,
                      payload:
                        (data.data.body.hospitalData &&
                          data.data.body.hospitalData[0]) ||
                        {},
                    });
                    dispatch({
                      type: HOSPITAL_SEARCH_OLD_DATA,
                      payload:
                        (data.data.body.hospitalData &&
                          data.data.body.hospitalData[0]) ||
                        {},
                    });
                    hospitalId =
                      data.data.body.hospitalData.length == 1
                        ? data.data.body.hospitalData?.[0].hospitalId
                        : '0';
                    dispatch({
                      type: SEARCH_CONSULTING_TIME,
                      payload: [],
                    });
                    if (
                      data.data.body.doctorId !== '' &&
                      hospitalId !== undefined &&
                      hospitalId !== '' &&
                      hospitalId != '0'
                    ) {
                      dispatch(
                        onGetSearchConsultingTime(
                          data.data.body.doctorId,
                          hospitalId,
                          value.userCode,
                        ),
                      );
                    }
                  }
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchDoctor',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchDoctor',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchDoctor',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchDoctor',
          '0',
          err,
        ),
      );
    }
  };
};

//search match hospital details
// doctor tab seacrh hospital using hospitalName
export const onGetSearchHospital = (value, doctorId) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/doctorDtls/searchHospitalJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorDtls/searchHospital', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: HOSPITAL_SEARCH_DATA,
                  payload: data.data.body,
                });

                if (
                  data.data.body.hospitalId !== '' &&
                  doctorId !== undefined &&
                  doctorId !== ''
                ) {
                  dispatch(
                    onGetSearchConsultingTime(
                      doctorId,
                      data.data.body.hospitalId,
                      value.userCode,
                    ),
                  );
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchHospital',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchHospital',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchHospital',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchHospital',
          '0',
          err,
        ),
      );
    }
  };
};

// doctor tab seacrh doctor using mobile No/register
export const onGetSearchConsultingTime = (doctorId, hospitalId, userCode) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      var value = {
        userCode: userCode,
        doctorId: doctorId,
        hospitalId: hospitalId,
      };

      jwtAxios
        .post('v1/doctorDtls/searchConsultingTimeJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorDtls/searchConsultingTime', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: SEARCH_CONSULTING_TIME,
                  payload: data.data.body,
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchConsultingTime',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchConsultingTime',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchConsultingTime',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchConsultingTime',
          '0',
          err,
        ),
      );
    }
  };
};
// doctor tab edit load event
export const onGetDoctorInfo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/doctorDtls/getDoctorJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorDtls/getDoctor', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: GET_DOCTOR_EDITDATA,
                    payload: data.data.body,
                  });
                  dispatch({
                    type: GET_DOCTOR_SUGGESTION_HOSPITAL,
                    payload: data?.data?.body?.suggestion_hospitals || [],
                  });
                  if (data && data.data && data.data.body.doctorhospitals) {
                    sessionStorage.setItem('docSaved', false);
                    dispatch({
                      type: DOCTORS_SUGGESTIONS,
                      payload: data.data.body.doctorhospitals,
                    });
                  } else {
                    sessionStorage.setItem('docSaved', true);
                  }
                  dispatch({
                    type: GETHOSPITAL_ID,
                    payload: data.data.body,
                  });
                  dispatch({
                    type: DOCTOR_AUTOFILL,
                    payload: data.data.body.autoFill,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDoctorInfo',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDoctorInfo',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorInfo',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetDoctorInfo', '0', err),
      );
    }
  };
};

// doctor tab edit load event
export const onGetIPDDoctorInfo = (value) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/ipdPres/getIPDdoctorjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/ipdPres/getIPDdoctor', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_DOCTOR_EDITDATA,
                    payload: data.data.body,
                  });
                  dispatch({
                    type: IPD_DYNFIELD_LIST,
                    payload: data.data.body?.dynFieldList
                      ? data.data.body?.dynFieldList
                      : [],
                  });
                  dispatch({
                    type: GET_DYNFIELD_STS,
                    payload: 'Success',
                  });

                  if (data?.data?.body?.consultationdoctorDtls) {
                    dispatch({
                      type: GET_CONSULTANT_DOCTOR_EDITDATA,
                      payload: data.data.body.consultationdoctorDtls,
                    });
                  }
                  dispatch({
                    type: GET_DOCTOR_SUGGESTION_HOSPITAL,
                    payload: data?.data?.body?.suggestion_hospitals || [],
                  });
                  if (data && data.data && data.data.body.doctorhospitals) {
                    dispatch({
                      type: DOCTORS_SUGGESTIONS,
                      payload: data.data.body.doctorhospitals,
                    });
                  }
                  dispatch({
                    type: DOCTOR_AUTOFILL,
                    payload: data.data.body.autoFill,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDoctorInfo',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDoctorInfo',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorInfo',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetDoctorInfo', '0', err),
      );
    }
  };
};
// doctor tab edit load event
export const onGetAmazonDoctorInfo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/teleConsultant/getAmazonDoctorjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/teleConsultant/getAmazonDoctor', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_AMAZON_DOCTOR,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDoctorInfo',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDoctorInfo',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorInfo',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetDoctorInfo', '0', err),
      );
    }
  };
};

// doctor tab edit load event
export const onGetHospitalInfo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/doctorDtls/getHospitalJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorDtls/getHospital', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  let hospitalId =
                    data.data && data.data.body && data.data.body.hospitalId
                      ? data.data.body.hospitalId
                      : '';
                  sessionStorage.setItem('hospitalId', hospitalId);
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getHospitalJwt',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getHospitalJwt',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getHospitalJwt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'getHospitalJwt', '0', err),
      );
    }
  };
};

// doctor tab edit load event
export const ipdTransferCall = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/pateRxMgmt/ipdTransferJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/pateRxMgmt/ipdTransfer', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  if (value.userCode != null) {
                    dispatch({
                      type: DASHBOARD_PENDING_DATA,
                      payload: [],
                    });
                    dispatch({
                      type: DASHBOARD_PENDING_COUNT,
                      payload: 0,
                    });
                    dispatch(
                      onGetDashboardPendingListData({
                        userCode: value.userCode,
                        userId: '',
                        status: '0',
                        offset: 0,
                        limit: 10,
                        filtercheckInStartDate: '',
                        filtercheckInEndDate: '',
                        filterfullfillmentStartDate: '',
                        filterfullfillmentEndDate: '',
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: value.session_id,
                      }),
                    );
                  }
                  dispatch({
                    type: DASHBOARD_SUCCESS_MSG,
                    payload:
                      data.data.message == 'Success'
                        ? 'Transferred successfully'
                        : 'Unable to transfer',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getHospitalJwt',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getHospitalJwt',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getHospitalJwt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'getHospitalJwt', '0', err),
      );
    }
  };
};

// save doctor api
export const saveDoctorDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/createjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/doctorDtls/create', value)
              .then((data) => {
                if (data?.data?.body?.doctorList) {
                  dispatch({
                    type: MATCHED_DOCTORS,
                    payload: data.data.body.doctorList,
                  });
                  dispatch({
                    type: MATCHED_DOCTORS_SUCCESS,
                    payload: 'true',
                  });
                  dispatch({
                    type: DOCTOR_SUCCESS_MSG,
                    payload: '',
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: '',
                  });
                  dispatch({
                    type: DOCTOR_SELECT_DATA,
                    payload: '',
                  });
                  return;
                } else {
                  dispatch({
                    type: MATCHED_DOCTORS_SUCCESS,
                    payload: 'false',
                  });
                }
                dispatch({
                  type: SET_DOCTOR_ID_NAME,
                  payload: {
                    DocID: data.data.body.doctorId,
                    DocName: data.data.body.doctorName,
                    RegisterNo: data.data.body.registerNo,
                    EmailId: data.data.body.email,
                    uniqueDocID: data.data.body.uniqueDocID,
                    docDept: data?.data?.body?.gangaramDocDept,
                  },
                });
                localStorage.setItem('skipSave', false);
                sessionStorage.setItem('docSaved', true);
                sessionStorage.setItem('DocID', data.data.body.doctorId);
                sessionStorage.setItem('DocName', data.data.body.doctorName);
                sessionStorage.setItem(
                  'hospitalId',
                  data.data.body.hospitalId || '',
                );
                dispatch({
                  type: GETHOSPITAL_ID,
                  payload: data.data.body,
                });
                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: {},
                });
                dispatch({
                  type: DOCTORS_SUGGESTIONS,
                  payload: {},
                });
                dispatch({
                  type: DOCTOR_AUTOFILL,
                  payload: {},
                });

                // dispatch({
                //   type: SEARCH_CONSULTING_TIME,
                //   payload: [],
                // });
                dispatch({
                  type: DOCTOR_SUCCESS_MSG,
                  payload: data.data.message,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Doctor info updated successfully',
                });
                // setTimeout(() => {
                //   dispatch(
                //     onSuggestionMedicine({
                //       userId: value.userId,
                //       erxReqId: value.erxId,
                //       userCode: value.userCode,
                //       doctorId: data?.data?.body?.doctorId || '',
                //     }),
                //   );
                // }, 1500);
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'saveDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'saveDoctorDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveDoctorDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'saveDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// save doctor IPD api
export const saveDoctorIPDDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/createIPDjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/ipdPres/createIPD', value)
              .then((data) => {
                if (
                  data?.data?.body?.doctorList ||
                  data?.data?.body?.ConsultantdoctorList
                ) {
                  dispatch({
                    type: MATCHED_DOCTORS,
                    payload: data.data.body.doctorList,
                  });
                  // if(data?.data?.body?.consultantFlag)
                  // {
                  //   dispatch({
                  //     type:DUPLICATE_DOCTOR_POUP,
                  //     payload:data?.data?.body?.consultantFlag
                  //   })
                  // }
                  if (value.newConsultant) {
                    dispatch({
                      type: MATCHED_CONSULTANT_DOCTORS,
                      payload: data.data.body.ConsultantdoctorList,
                    });
                  }
                  dispatch({
                    type: SHOWN_GEN_FIELD,
                    payload: data.data.body.shownGenDtls,
                  });
                  dispatch({
                    type: MATCHED_DOCTORS_SUCCESS,
                    payload: 'true',
                  });
                  dispatch({
                    type: DOCTOR_SUCCESS_MSG,
                    payload: '',
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: '',
                  });
                  dispatch({
                    type: DOCTOR_SELECT_DATA,
                    payload: '',
                  });
                  dispatch({
                    type: DOCTOR_CONSULTANT_SELECT_DATA,
                    payload: '',
                  });

                  return;
                } else {
                  if (
                    value?.newDoctor == true &&
                    value?.newConsultant == true &&
                    data?.data?.body?.consultantFlag == 'Primary'
                  ) {
                    dispatch({
                      type: MATCHED_DOCTORS_SUCCESS,
                      payload: 'true',
                    });
                    // dispatch({
                    //   type:DUPLICATE_DOCTOR_POUP,
                    //   payload:data?.data?.body?.consultantFlag
                    // })
                  } else {
                    if (data?.data?.body?.consultantFlag == 'Primary') {
                      dispatch({
                        type: MATCHED_DOCTORS_SUCCESS,
                        payload: 'true',
                      });
                    } else {
                      dispatch({
                        type: MATCHED_DOCTORS_SUCCESS,
                        payload: 'false',
                      });
                    }
                  }
                }
                dispatch({
                  type: IPD_DYNFIELD_LIST,
                  payload: data?.data?.body?.dynFieldList
                    ? data?.data?.body?.dynFieldList
                    : [],
                });
                dispatch({
                  type: GET_DYNFIELD_STS,
                  payload: 'Success',
                });
                dispatch({
                  type: SET_DOCTOR_ID_NAME,
                  payload: {
                    DocID: data.data.body.doctorId,
                    DocName: data.data.body.doctorName,
                    RegisterNo: data.data.body.registerNo,
                    EmailId: data.data.body.email,
                    uniqueDocID: data.data.body.uniqueDocID,
                  },
                });
                // localStorage.setItem('skipSave', false);
                sessionStorage.setItem('DocID', data.data.body.doctorId);
                sessionStorage.setItem('DocName', data.data.body.doctorName);
                sessionStorage.setItem(
                  'hospitalId',
                  data.data.body.hospitalId || '',
                );
                dispatch({
                  type: SET_DOCTOR_HOSPITAL_ID,
                  payload: data.data.body.hospitalId || '',
                });
                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: {},
                });
                dispatch({
                  type: DOCTORS_SUGGESTIONS,
                  payload: {},
                });
                dispatch({
                  type: DOCTOR_AUTOFILL,
                  payload: {},
                });

                // dispatch({
                //   type: SEARCH_CONSULTING_TIME,
                //   payload: [],
                // });
                dispatch({
                  type: DOCTOR_SUCCESS_MSG,
                  payload: data.data.message,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Doctor info updated successfully',
                });
                if (
                  data?.data?.body?.consultantFlag == '' ||
                  data?.data?.body?.consultantFlag == 'Consultant'
                ) {
                  setTimeout(() => {
                    dispatch(
                      onSuggestionMedicine({
                        userId: value.userId,
                        erxReqId: value.erxId,
                        userCode: value.userCode,
                        doctorId: data?.data?.body?.doctorId || '',
                      }),
                    );
                  }, 1500);
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'saveDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'saveDoctorDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveDoctorDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'saveDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// save doctor api
export const saveAmazonDoctorDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      let passparams = {
        erxId: value.erxId,
        userCode: value.userCode,
        status: '1',
      };
      jwtAxios
        .post(
          'v1/teleConsultant/teleConsultationCheckDuplicateEntries',
          passparams,
        )
        .then((data) => {
          if (data?.data?.body?.duplicate_entry == 'false') {
            jwtAxios
              .post('v1/teleConsultant/updateAmazonDoctorDetailsJwt', value)
              .then((data) => {
                try {
                  value['jwtToken'] = data.data.body['token'];

                  jwtAxios
                    .post('v1/teleConsultant/updateAmazonDoctorDetails', value)
                    .then((data) => {
                      try {
                        if (data.data.status === 200) {
                          dispatch(
                            onGetAmazonPendingPatInfn({
                              userCode: value.userCode,
                              erxid: value.erxId,
                              patId: value.patid,
                            }),
                          );
                          // setTimeout(() => {
                          //   dispatch({
                          //     type: SHOW_MESSAGE,
                          //     payload: 'Doctor info updated successfully',
                          //   });
                          // }, 100);
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    })
                    .catch((error) => {
                      dispatch({type: FETCH_ERROR, payload: error.message});
                      dispatch(
                        onWriteCloudWatchLog(
                          streamname,
                          filename,
                          'saveAmazonDoctorDetails',
                          '0',
                          error,
                        ),
                      );
                    });
                } catch (err) {
                  dispatch(fetchError(err.response.message));
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'saveAmazonDoctorDetails',
                      '0',
                      err,
                    ),
                  );
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'saveAmazonDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            dispatch({type: FETCH_ERROR, payload: TC_DUPLICATE_MESSAGE});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateAmazonPatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'saveAmazonDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};
// update doctor api
export const updateDoctorDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/updatejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/doctorDtls/update', value)
              .then((data) => {
                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: {},
                });
                dispatch({
                  type: DOCTORS_SUGGESTIONS,
                  payload: {},
                });
                dispatch({
                  type: DOCTOR_AUTOFILL,
                  payload: {},
                });

                dispatch({
                  type: SEARCH_CONSULTING_TIME,
                  payload: [],
                });
                dispatch({
                  type: DOCTOR_UPDATE_MSG,
                  payload: data.data.message,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: data.data.message,
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateDoctorDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateDoctorDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//Insert and Update prescription detaails
export const onInsertPrescriptionDetails = ({
  userId,
  erxReqId,
  userCode,
  patId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, erxReqId, userCode, patId};
    //Call insert data
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertTeleConsultationAuditData', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            const getdata = data.data.body;
            dispatch({
              type: EXIST_COUNT,
              payload: getdata,
            });
            dispatch({
              type: DASHBOARD_INSERT_PRESCRIPTION,
              payload: getdata,
            });
            dispatch({
              type: EXIST_COUNT,
              payload: getdata,
            });
            dispatch({
              type: INSERT_EXIST_COUNT,
              payload: getdata,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onInsertPrescriptionDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onInsertPrescriptionDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//Check if already exists
export const onCheckAlreadyTakeneRx = ({userId, erxId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, erxId, userCode};
    //Call alreadytaken erx data
    try {
      jwtAxios
        .post('v1/pateRxMgmt/checkAlreadytakeneRx', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            const getdata = data.data.body;
            dispatch({
              type: EXIST_COUNT,
              payload: getdata,
            });
            dispatch({
              type: FETCH_ERROR,
              payload: 'This erx already taken',
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onCheckAlreadyTakeneRx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onCheckAlreadyTakeneRx',
          '0',
          err,
        ),
      );
    }
  };
};
//Get dashboard inprogress List Data
export const onGetDashboardInprogressListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };

    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/pateRxMgmt/geteRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatainprogres = data?.data?.body?.data;
                    dispatch({
                      type: DASHBOARD_INPROGRESSS_DATA,
                      payload: getdatainprogres,
                    });
                    dispatch({
                      type: DASHBOARD_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DASHBOARD_INPROGRESSS_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardInprogressListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardInprogressListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardInprogressListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardInprogressListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetAmazonInprogressListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
  filterMobileNo,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      filterMobileNo,
      session_id,
    };

    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazoneRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/teleConsultant/getAmazoneRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatainprogres = data.data.body.data;
                    dispatch({
                      type: AMAZON_INPROGRESSS_DATA,
                      payload: getdatainprogres,
                    });
                    dispatch({
                      type: TELE_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: AMAZON_INPROGRESSS_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardInprogressListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardInprogressListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardInprogressListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardInprogressListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetDashboardCompeletdListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/pateRxMgmt/geteRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatacompleted = data.data.body.data;
                    dispatch({
                      type: DASHBOARD_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DASHBOARD_COMPLETED_DATA,
                      payload: getdatacompleted,
                    });
                    dispatch({
                      type: DASHBOARD_COMPLETED_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardCompeletdListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardCompeletdListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardCompeletdListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardCompeletdListData',
          '0',
          err,
        ),
      );
    }
  };
};
//Get dashboard erx count
export const onGetDashboardErxCountList = ({userCode, userId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getDashboardErxCountjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                jwtToken,
              };
              jwtAxios
                .post('v1/pateRxMgmt/getDashboardErxCount', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    if (
                      data?.data?.body?.data &&
                      Object.keys(data.data.body.data).length > 0
                    ) {
                      //Get erx completed count and store local
                      dispatch({
                        type: TOTAL_DASHBOARD_COMPLETED_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.completedDataCount
                          : '0',
                      });
                      let overallCompletedCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallCompletedCount')
                            ? JSON.parse(
                                localStorage.getItem('overallCompletedCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.completedDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.completedDataCount
                          : '';
                      localStorage.setItem(
                        'overallCompletedCount',
                        overallCompletedCount,
                      );
                      //Get erx pending count and store local
                      dispatch({
                        type: TOTAL_DASHBOARD_PENDING_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.pendingDataCount
                          : '0',
                      });
                      let overallPendingCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallPendingCount')
                            ? JSON.parse(
                                localStorage.getItem('overallPendingCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.pendingDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.pendingDataCount
                          : '';

                      localStorage.setItem(
                        'overallPendingCount',
                        overallPendingCount,
                      );
                      //Get erx rejected count and store local
                      dispatch({
                        type: TOTAL_DASHBOARD_REJECT_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.rejectedDataCount
                          : '',
                      });

                      let overallRejectCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallRejectCount')
                            ? JSON.parse(
                                localStorage.getItem('overallRejectCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.rejectedDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.rejectedDataCount
                          : '';
                      localStorage.setItem(
                        'overallRejectCount',
                        overallRejectCount,
                      );
                      //Get erx inprogress count and store local
                      dispatch({
                        type: TOTAL_DASHBOARD_INPROGRESSS_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.inProgressDataCount
                          : '',
                      });
                      let overallInProgressCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallInProgressCount')
                            ? JSON.parse(
                                localStorage.getItem('overallInProgressCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.inProgressDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.inProgressDataCount
                          : '';
                      localStorage.setItem(
                        'overallInProgressCount',
                        overallInProgressCount,
                      );

                      //Get erx call completed count and store local
                      dispatch({
                        type: TOTAL_DASHBOARD_CALLCOMPLETE_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.calbackcompletedDataCount
                          : '',
                      });

                      let overallCallCompletedCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallCallCompletedCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallCallCompletedCount',
                                ),
                              )
                            : data.data.body.data
                            ? data.data.body.data.calbackcompletedDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.calbackcompletedDataCount
                          : '';
                      localStorage.setItem(
                        'overallCallCompletedCount',
                        overallCallCompletedCount,
                      );
                      //Get erx call pending count and store local
                      dispatch({
                        type: TOTAL_DASHBOARD_CALLBACk_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.calbackPendingDataCount
                          : '',
                      });

                      let overallCallPendingCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallCallPendingCount')
                            ? JSON.parse(
                                localStorage.getItem('overallCallPendingCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.calbackPendingDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.calbackPendingDataCount
                          : '';
                      localStorage.setItem(
                        'overallCallPendingCount',
                        overallCallPendingCount,
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardCOunt',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardCOunt',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardCOunt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardCompeletdListData',
          '0',
          err,
        ),
      );
    }
  };
};
//Get dashboard erx count
export const onGetAmazonDashboardErxCountList = ({userCode, userId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazonDashboardErxCountjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                jwtToken,
              };
              jwtAxios
                .post('v1/teleConsultant/getAmazonDashboardErxCount', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    if (
                      data?.data?.body?.data &&
                      Object.keys(data.data.body.data).length > 0
                    ) {
                      //Get erx overallAmazonCompletedCount count and store local
                      dispatch({
                        type: TOTAL_AMAZON_COMPLETED_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.completedDataCount
                          : '0',
                      });
                      let overallCompletedCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallAmazonCompletedCount')
                            ? JSON.parse(
                                localStorage.getItem('overallCompletedCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.completedDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.completedDataCount
                          : '';
                      localStorage.setItem(
                        'overallAmazonCompletedCount',
                        overallCompletedCount,
                      );

                      //Get erx overallAmazonPendingCount count and store local
                      let overallPendingCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallAmazonPendingCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallAmazonPendingCount',
                                ),
                              )
                            : data.data.body.data
                            ? data.data.body.data.pendingDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.pendingDataCount
                          : '';

                      dispatch({
                        type: TOTAL_AMAZON_PENDING_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.pendingDataCount
                          : '',
                      });

                      localStorage.setItem(
                        'overallAmazonPendingCount',
                        overallPendingCount,
                      );

                      let overallFailureCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallAmazonFailureCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallAmazonFailureCount',
                                ),
                              )
                            : data.data.body.data
                            ? data.data.body.data.failureDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.failureDataCount
                          : '';
                      dispatch({
                        type: TOTAL_AMAZON_FAILURE_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.failureDataCount
                          : '',
                      });
                      localStorage.setItem(
                        'overallAmazonFailureCount',
                        overallFailureCount,
                      );
                      //Get erx TOTAL_AMAZON_REJECT_COUNT count and store local
                      dispatch({
                        type: TOTAL_AMAZON_REJECT_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.rejectedDataCount
                          : '',
                      });

                      let overallRejectCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallAmazonRejectCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallAmazonRejectCount',
                                ),
                              )
                            : data.data.body.data
                            ? data.data.body.data.rejectedDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.rejectedDataCount
                          : '';
                      localStorage.setItem(
                        'overallAmazonRejectCount',
                        overallRejectCount,
                      );
                      //Get erx TOTAL_AMAZON_INPROGRESS_COUNT count and store local
                      dispatch({
                        type: TOTAL_AMAZON_INPROGRESS_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.inProgressDataCount
                          : '',
                      });
                      let overallInProgressCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallAmazonInProgressCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallAmazonInProgressCount',
                                ),
                              )
                            : data.data.body.data
                            ? data.data.body.data.inProgressDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.inProgressDataCount
                          : '';
                      localStorage.setItem(
                        'overallAmazonInProgressCount',
                        overallInProgressCount,
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardCOunt',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardCOunt',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardCOunt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardCompeletdListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetAmazonCompeletdListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      filterMobileNo,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazoneRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/teleConsultant/getAmazoneRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatacompleted = data.data.body.data;
                    dispatch({
                      type: TELE_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: AMAZON_COMPLETED_DATA,
                      payload: getdatacompleted,
                    });
                    dispatch({
                      type: AMAZON_COMPLETED_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetAmazonCompeletdListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetAmazonCompeletdListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAmazonCompeletdListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetAmazonCompeletdListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard reject List Data
export const onGetDashboardRejectListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/pateRxMgmt/geteRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdataReject = data.data.body.data;
                    dispatch({
                      type: DASHBOARD_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DASHBOARD_REJECT_DATA,
                      payload: getdataReject,
                    });
                    dispatch({
                      type: DASHBOARD_REJECT_COUNT,
                      payload: getdataReject && getdataReject.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardRejectListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardRejectListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardRejectListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardRejectListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard reject List Data
export const onGetAmazonRejectListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  filterRejectId,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterMobileNo,
      filterRejectId,
      filterVendor,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazoneRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/teleConsultant/getAmazoneRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdataReject = data.data.body.data;
                    dispatch({
                      type: TELE_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: AMAZON_REJECT_DATA,
                      payload: getdataReject,
                    });
                    dispatch({
                      type: AMAZON_REJECT_COUNT,
                      payload: getdataReject && getdataReject.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetAmazonRejectListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetAmazonRejectListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAmazonRejectListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetAmazonRejectListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetDashboardCallCompeletdListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterBookingStatus,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterBookingStatus,
      filterVendor,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getcallbackListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/pateRxMgmt/getcallbackList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: DASHBOARD_LIST_RESULT,
                      payload: true,
                    });
                    const getdatacompleted = data.data.body.data;
                    dispatch({
                      type: DASHBOARD_CALL_COMPLETED_DATA,
                      payload: getdatacompleted,
                    });
                    dispatch({
                      type: DASHBOARD_CALL_COMPLETED_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardCallCompeletdListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardCallCompeletdListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardCallCompeletdListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardCallCompeletdListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetDashboardCallPendingListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getcallbackListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/pateRxMgmt/getcallbackList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: DASHBOARD_LIST_RESULT,
                      payload: true,
                    });
                    const getdatacompleted = data.data.body.data;
                    dispatch({
                      type: DASHBOARD_CALL_PENDING_DATA,
                      payload: getdatacompleted,
                    });
                    dispatch({
                      type: DASHBOARD_CALL_PENDING_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardCallPendingListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardCallPendingListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardCallPendingListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardCallPendingListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard reject List Data
export const sendtopatients = (passdata) => {
  return (dispatch) => {
    //Call inprogress list Token  - Get Token
    localStorage.setItem('pdfsent', false);
    try {
      jwtAxios
        .post('v1/pateRxMgmt/erxPdfGeneration', passdata)
        .then((res) => {
          var snoozeres = res.data.body.snooze ? res.data.body.snooze[0] : '';
          dispatch({
            type: SEND_SUCCESS_MSG,
            payload: 'eRx has been successfully send to patient',
          });
          if (snoozeres) {
            localStorage.setItem('snoozeTime', JSON.stringify(snoozeres));
          }
          localStorage.setItem('pdfsent', true);
          //Get pending list data from api
          if (passdata.userCode != null) {
            dispatch({
              type: DASHBOARD_PENDING_DATA,
              payload: [],
            });
            dispatch({
              type: DASHBOARD_PENDING_COUNT,
              payload: 0,
            });
            dispatch(
              onGetDashboardPendingListData({
                userCode: passdata.userCode,
                userId: '',
                status: '0',
                offset: 0,
                limit: 10,
                filtercheckInStartDate: '',
                filtercheckInEndDate: '',
                filterfullfillmentStartDate: '',
                filterfullfillmentEndDate: '',
                filterpatName: '',
                filterMedia: '',
                filterService: [],
                filterVendor: '',
                session_id: passdata.session_id,
              }),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendtopatients',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'sendtopatients', '0', err),
      );
    }
  };
};
export const submitWordPres = (passdata) => {
  return (dispatch) => {
    //Call inprogress list Token  - Get Token
    localStorage.setItem('pdfsent', false);
    try {
      jwtAxios
        .post('v1/pateRxMgmt/generateWord', passdata)
        .then((data) => {
          dispatch({
            type: GENERATE_PDF,
            payload: data.data.body,
          });
          let getdata = data?.data?.body;
          if (getdata && getdata.erxImageOCR.length > 0) {
            var imgurl = [];
            var imgocr = [];
            for (var i = 0; i < getdata.erxImageOCR.length; i++) {
              //Push only images
              imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
              //Push Image with ocr data and extension
              imgocr.push({
                url: getdata.erxImageOCR[i].erxUrl,
                ocrdata: getdata.erxImageOCR[i].ocrData,
                extension: getdata.erxImageOCR[i].urlExtension,
                rownumber: getdata.erxImageOCR[i].rownumber,
              });
            }

            dispatch({
              type: ERX_IMG_ARR,
              payload: imgurl,
            });
            dispatch({
              type: ERX_IMG_OCR,
              payload: imgocr,
            });
          }
          if (getdata && getdata.clientDetails) {
            dispatch({
              type: CLIENT_DETAILS,
              payload: getdata.clientDetails[0],
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendtopatients',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'sendtopatients', '0', err),
      );
    }
  };
};
//Get PDF URL
export const generatePDF = (passdata) => {
  return (dispatch) => {
    //Call generate pdf
    try {
      jwtAxios
        .post('v1/pateRxMgmt/generatePDF', passdata)
        .then((data) => {
          dispatch({
            type: GENERATE_PDF,
            payload: data.data.body,
          });
          let getdata = data?.data?.body?.data;
          if (getdata && getdata.erxImageOCR.length > 0) {
            var imgurl = [];
            var imgocr = [];
            for (var i = 0; i < getdata.erxImageOCR.length; i++) {
              //Push only images
              imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
              //Push Image with ocr data and extension
              imgocr.push({
                url: getdata.erxImageOCR[i].erxUrl,
                ocrdata: getdata.erxImageOCR[i].ocrData,
                extension: getdata.erxImageOCR[i].urlExtension,
                rownumber: getdata.erxImageOCR[i].rownumber,
              });
            }

            if (
              getdata &&
              getdata?.immunizationUrl &&
              getdata?.immunizationUrl.length > 0
            ) {
              dispatch({
                type: PREVIOUS_ERXID,
                payload: getdata.immunizationUrl[0].prevErxId || [],
              });
            } else
              dispatch({
                type: PREVIOUS_ERXID,
                payload: [],
              });

            dispatch({
              type: ERX_IMG_ARR,
              payload: imgurl,
            });
            dispatch({
              type: ERX_IMG_OCR,
              payload: imgocr,
            });
          }
          if (getdata && getdata.clientDetails) {
            dispatch({
              type: CLIENT_DETAILS,
              payload: getdata.clientDetails,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'generatePDF',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'generatePDF', '0', err),
      );
    }
  };
};

//Get IPD PDF URL
export const generateIPDPDF = (passdata) => {
  return (dispatch) => {
    //Call generate pdf
    try {
      jwtAxios
        .post('v1/ipdPres/generateIpdPDF', passdata)
        .then((data) => {
          dispatch({
            type: GENERATE_PDF,
            payload: data.data.body,
          });
          let getdata = data?.data?.body?.data;
          if (getdata && getdata.erxImageOCR.length > 0) {
            var imgurl = [];
            var imgocr = [];
            for (var i = 0; i < getdata.erxImageOCR.length; i++) {
              //Push only images
              imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
              //Push Image with ocr data and extension
              imgocr.push({
                url: getdata.erxImageOCR[i].erxUrl,
                ocrdata: getdata.erxImageOCR[i].ocrData,
                extension: getdata.erxImageOCR[i].urlExtension,
                rownumber: getdata.erxImageOCR[i].rownumber,
              });
            }

            dispatch({
              type: ERX_IMG_ARR,
              payload: imgurl,
            });
            dispatch({
              type: ERX_IMG_OCR,
              payload: imgocr,
            });
          }
          if (getdata && getdata.clientDetails) {
            dispatch({
              type: CLIENT_DETAILS,
              payload: getdata.clientDetails,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'generateIPDPDF',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err?.response?.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'generateIPDPDF', '0', err),
      );
    }
  };
};
//Get PDF URL
export const generateAmazonPDF = (passdata) => {
  return (dispatch) => {
    //Call generate pdf
    try {
      jwtAxios
        .post('v1/teleConsultant/generateAmazonPDF', passdata)
        .then((data) => {
          dispatch({
            type: GENERATE_AMAZON_PDF,
            payload: data.data.body,
          });
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'generatePDF',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'generatePDF', '0', err),
      );
    }
  };
};

export const storeOnlineOfflineStatus = (value) => {
  return (dispatch) => {
    try {
      jwtAxios
        .post('v1/userMgmt/storeOnlineOfflineJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogresetUserPaswds list data - Get pending list data using token
            try {
              value['jwtToken'] = data.data.body.token;
              jwtAxios
                .post('v1/userMgmt/storeOnlineOffline', value)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: ONLINE_OFFLINE_STATUS,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'storeOnlineOfflineStatus',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'storeOnlineOfflineStatus',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'storeOnlineOfflineStatus',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'storeOnlineOfflineStatus',
          '0',
          err,
        ),
      );
    }
  };
};

// Add eRx Medicine details
export const insertOperatorNotesApi = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertOperatorNotes', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            let passdata = {
              erxId: value.erxReqId,
              userCode: value.userCode,
              prescriptionType: value?.prescriptionType || '',
            };
            dispatch(onGeteRxNotes(passdata));
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertOperatorNotesApi',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'insertOperatorNotesApi',
          '0',
          err,
        ),
      );
    }
  };
};
// Add eRx Medicine details
export const insertCallAttemptReasons = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      let passparams = {
        erxId: value.erxReqId,
        userCode: value.userCode,
        status: '1',
      };
      jwtAxios
        .post(
          'v1/teleConsultant/teleConsultationCheckDuplicateEntries',
          passparams,
        )
        .then((data) => {
          if (data?.data?.body?.duplicate_entry == 'false') {
            jwtAxios
              .post('v1/teleConsultant/insertConsultaionFailed', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEND_AMAZON_SUCCESS_MSG,
                    payload: data.data.body.result_msg
                      ? data.data.body.result_msg
                      : 'Consultation Failed!',
                  });
                  let passdata = {
                    erxId: value.erxReqId,
                    userCode: value.userCode,
                  };
                  dispatch(onGeteRxCallReasons(passdata));
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'insertOperatorNotesApi',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            setTimeout(() => {
              dispatch({type: FETCH_ERROR, payload: TC_DUPLICATE_MESSAGE});
            }, 500);
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateAmazonPatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'insertOperatorNotesApi',
          '0',
          err,
        ),
      );
    }
  };
};
export const insertCallBacks = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      let passparams = {
        erxId: value.erxId,
        userCode: value.userCode,
        status: '1',
      };
      jwtAxios
        .post(
          'v1/teleConsultant/teleConsultationCheckDuplicateEntries',
          passparams,
        )
        .then((data) => {
          if (data?.data?.body?.duplicate_entry == 'false') {
            jwtAxios
              .post('v1/teleConsultant/updateCallbackDetailsJwt', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  try {
                    value['jwtToken'] = data.data.body.token;
                    jwtAxios
                      .post('v1/teleConsultant/updateCallbackDetails', value)
                      .then((data) => {
                        if (data.status === 200) {
                          dispatch({type: FETCH_SUCCESS});
                          let passdata = {
                            userCode: value.userCode,
                            erxid: value.erxId,
                            patId: value.patId,
                          };
                          dispatch(onGetAmazonPendingPatInfn(passdata));
                          // dispatch({
                          //   type: CALLBACK_SUCCESS_MSG,
                          //   payload: data.data.body,
                          // });
                          dispatch({
                            type: SEND_AMAZON_SUCCESS_MSG,
                            payload: data.data.body,
                          });
                        }
                      });
                  } catch (err) {
                    dispatch({type: FETCH_ERROR, payload: err});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'insertCallBacks',
                        '0',
                        err,
                      ),
                    );
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'insertCallBacks',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            dispatch({type: FETCH_ERROR, payload: TC_DUPLICATE_MESSAGE});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertCallBacks',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'insertCallBacks', '0', err),
      );
    }
  };
};
// Add eRx Medicine details
export const rejectAmazonRx = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      let passparams = {
        erxId: value.erxId,
        userCode: value.userCode,
        status: '1',
      };
      jwtAxios
        .post(
          'v1/teleConsultant/teleConsultationCheckDuplicateEntries',
          passparams,
        )
        .then((data) => {
          if (data?.data?.body?.duplicate_entry == 'false') {
            jwtAxios
              .post('v1/teleConsultant/rejectAmazoneRxJwt', value)
              .then((data) => {
                if (data.data.status === 200) {
                  try {
                    value['jwtToken'] = data.data.body.token;
                    jwtAxios
                      .post('v1/teleConsultant/rejectAmazoneRx', value)
                      .then((data) => {
                        dispatch({type: FETCH_SUCCESS});
                        let passdata = {
                          erxId: value.erxId,
                          userCode: value.userCode,
                        };
                        dispatch({
                          type: SEND_AMAZON_SUCCESS_MSG,
                          payload: 'eRx Rejected Successfully',
                        });
                        dispatch(onGeteRxCallReasons(passdata));
                        let passdata1 = {
                          userCode: value.userCode,
                          userId: '',
                          status: '4',
                          offset: 0,
                          limit: 25,
                          filtercheckInStartDate: localStorage.getItem(
                            'AmazonFilterFromDate',
                          )
                            ? localStorage.getItem('AmazonFilterFromDate')
                            : '',
                          filtercheckInEndDate: localStorage.getItem(
                            'AmazonFilterToDate',
                          )
                            ? localStorage.getItem('AmazonFilterToDate')
                            : '',
                          filterfullfillmentStartDate: '',
                          filterfullfillmentEndDate: '',
                          filterpatName: '',
                          filterMedia: '',
                          filterService: [],
                          filterVendor: '',
                          session_id: value.sessionId,
                          retryTag: true,
                        };
                        dispatch(
                          onGetAmazonDashboardSendFailureListData(passdata1),
                        );
                      });
                  } catch (err) {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'insertOperatorNotesApi',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            dispatch({type: FETCH_ERROR, payload: TC_DUPLICATE_MESSAGE});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'rejectAmazonRx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'insertOperatorNotesApi',
          '0',
          err,
        ),
      );
    }
  };
};

//Add medicine details to marg data
export const addMedcineData = (value) => {
  return (dispatch) => {
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertAddMedicinejwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogresetUserPaswds list data - Get pending list data using token
            try {
              value['jwtToken'] = data.data.body.token;
              jwtAxios
                .post('v1/pateRxMgmt/insertAddMedicine', value)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: ADD_MEDICINE,
                      payload: 'Yes',
                    });
                  } else {
                    dispatch({
                      type: ADD_MEDICINE,
                      payload: 'No',
                    });
                  }
                })
                .catch((error) => {
                  dispatch({
                    type: ADD_MEDICINE,
                    payload: 'No',
                  });
                  // dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'addMedcineData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'addMedcineData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'storeOnlineOfflineStatus',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'storeOnlineOfflineStatus',
          '0',
          err,
        ),
      );
    }
  };
};

//Add medicine details to marg data
export const addAmazonMedcineData = (value) => {
  return (dispatch) => {
    try {
      let passparams = {
        erxId: value.erxId,
        userCode: value.userCode,
        status: '1',
      };
      jwtAxios
        .post(
          'v1/teleConsultant/teleConsultationCheckDuplicateEntries',
          passparams,
        )
        .then((data) => {
          if (data?.data?.body?.duplicate_entry == 'false') {
            jwtAxios
              .post('v1/teleConsultant/insertAmazonMedicineDatajwt', value)
              .then((data) => {
                if (data.status === 200) {
                  //Call inprogresetUserPaswds list data - Get pending list data using token
                  try {
                    value['jwtToken'] = data.data.body.token;
                    jwtAxios
                      .post('v1/teleConsultant/insertAmazonMedicineData', value)
                      .then((data) => {
                        if (data.status === 200) {
                          dispatch({type: FETCH_SUCCESS});
                          dispatch({
                            type: ADD_MEDICINE,
                            payload: 'Yes',
                          });
                          let passdata = {
                            userId: value.userId,
                            erxReqId: value.erxId,
                            userCode: value.userCode,
                          };
                          dispatch(onGetAmazonMedicines(passdata));
                        } else {
                          dispatch({
                            type: ADD_MEDICINE,
                            payload: 'No',
                          });
                        }
                      })
                      .catch((error) => {
                        dispatch({
                          type: ADD_MEDICINE,
                          payload: 'No',
                        });
                        // dispatch({type: FETCH_ERROR, payload: error.message});
                        dispatch(
                          onWriteCloudWatchLog(
                            streamname,
                            filename,
                            'addMedcineData',
                            '0',
                            error,
                          ),
                        );
                      });
                  } catch (err) {
                    dispatch(fetchError(err.response.message));
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'addMedcineData',
                        '0',
                        err,
                      ),
                    );
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'storeOnlineOfflineStatus',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            dispatch({
              type: ADD_MEDICINE,
              payload: 'Yes',
            });
            let passdata = {
              userId: value.userId,
              erxReqId: value.erxId,
              userCode: value.userCode,
            };
            dispatch(onGetAmazonMedicines(passdata));
            setTimeout(() => {
              dispatch({type: FETCH_ERROR, payload: TC_DUPLICATE_MESSAGE});
            }, 500);
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateAmazonPatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'storeOnlineOfflineStatus',
          '0',
          err,
        ),
      );
    }
  };
};
//Geterx medcines data
export const onGeteRxMedicines = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};

              jwtAxios
                .post('v1/pateRxMgmt/geteRxDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    if (getdata.medicinedtls.length > 0) {
                      var arraydata = getdata.medicinedtls.map((e, i) => {
                        return {
                          saltName: e.saltName,
                          medName: e.medName,
                          mn: e.mn,
                          af: e.an,
                          en: e.en,
                          nt: e.nt,
                          unit: e.uomId,
                          unitcode: e.uomId,
                          quantity: e.dosage,
                          refferal: e.referral,
                          refferalHindi: e.referralHindi,
                          route: e.route,
                          advice: e.advice,
                          adviceHindi: e.adviceHindi,
                          duration: e.duration,
                          durationValue: e.durationValue,
                          frequency: e.frequency,
                          seqno: e.txnNo,
                          price: e.price,
                          status: e.status,
                          mCode: e.mCode,
                          company: e.company,
                          category: e.category,
                          dosage: e.dosage,
                          qty: e.qty,
                          strength1: e.strength1,
                          strength2: e.strength2,
                          type: e.type,
                          hindiname: e.hindiname,
                          strength: e.strength,
                          margqty: e.margqty,
                          newmrp: e.mrp,
                          reminder: e.reminder ? e.reminder : false,
                          rx_start_date: e.rx_start_date,
                          rx_end_date: e.rx_end_date,
                        };
                      });
                      dispatch({
                        type: DASHBOARD_ERX_MEDICINE_DETAIL,
                        payload: arraydata,
                      });
                    }
                    dispatch({
                      type: CLIENT_DETAILS,
                      payload: data.data.body.clientDetails,
                    });
                    if (getdata.genericmedicinedtls.length > 0) {
                      var arraydatageneric = getdata.genericmedicinedtls.map(
                        (e, i) => {
                          return {
                            saltName: e.saltName,
                            medName: e.medName,
                            mn: e.mn,
                            af: e.an,
                            en: e.en,
                            nt: e.nt,
                            unit: e.uomId,
                            unitcode: e.uomId,
                            quantity: e.dosage,
                            refferal: e.referral,
                            refferalHindi: e.referralHindi,
                            route: e.route,
                            advice: e.advice,
                            adviceHindi: e.adviceHindi,
                            duration: e.duration,
                            durationValue: e.durationValue,
                            frequency: e.frequency,
                            seqno: e.txnNo,
                            price: e.price,
                            status: e.status,
                            mCode: e.mCode,
                            company: e.company,
                            category: e.category,
                            dosage: e.dosage,
                            qty: e.qty,
                            strength1: e.strength1,
                            strength2: e.strength2,
                            type: e.type,
                            hindiname: e.hindiname,
                            strength: e.strength,
                            margqty: e.margqty,
                            newmrp: e.mrp,
                          };
                        },
                      );
                      dispatch({
                        type: DASHBOARD_GRX_MEDICINE_DETAIL,
                        payload: arraydatageneric,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGeteRxMedicines',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGeteRxMedicines',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxMedicines',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGeteRxMedicines',
          '0',
          err,
        ),
      );
    }
  };
};

//Geterx medcines data
export const onGetIPDeRxMedicines = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/getIPDeRxDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};

              jwtAxios
                .post('v1/ipdPres/getIPDeRxDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;

                    if (getdata.medicinedtls.length > 0) {
                      var arraydata = getdata.medicinedtls.map((e, i) => {
                        return {
                          saltName: e.saltName,
                          medName: e.medName,
                          mn: e.mn,
                          af: e.an,
                          en: e.en,
                          nt: e.nt,
                          unit: e.uomId,
                          unitcode: e.uomId,
                          quantity: e.dosage,
                          refferal: e.referral,
                          refferalHindi: e.referralHindi,
                          route: e.route,
                          advice: e.advice,
                          adviceHindi: e.adviceHindi,
                          duration: e.duration,
                          durationValue: e.durationValue,
                          frequency: e.frequency,
                          seqno: e.txnNo,
                          price: e.price,
                          status: e.status,
                          mCode: e.mCode,
                          company: e.company,
                          category: e.category,
                          dosage: e.dosage,
                          qty: e.qty,
                          strength1: e.strength1,
                          strength2: e.strength2,
                          type: e.type,
                          hindiname: e.hindiname,
                          strength: e.strength,
                          margqty: e.margqty,
                          newmrp: e.mrp,
                          reminder: e.reminder ? e.reminder : false,
                          rx_start_date: e.rx_start_date,
                          rx_end_date: e.rx_end_date,
                        };
                      });
                      dispatch({
                        type: DASHBOARD_ERX_MEDICINE_DETAIL,
                        payload: arraydata,
                      });
                    }
                    dispatch({
                      type: CLIENT_DETAILS,
                      payload: data.data.body.clientDetails,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetIPDeRxMedicines',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetIPDeRxMedicines',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetIPDeRxMedicines',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetIPDeRxMedicines',
          '0',
          err,
        ),
      );
    }
  };
};

//Geterx medcines data
export const onGetAmazonMedicines = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazonMedicineDtlsjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};

              jwtAxios
                .post('v1/teleConsultant/getAmazonMedicineDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data?.data?.body?.medicinedtls || '';
                    dispatch({
                      type: AMAZON_MED,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetAmazonMedicines',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetAmazonMedicines',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAmazonMedicines',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetAmazonMedicines',
          '0',
          err,
        ),
      );
    }
  };
};

// Approve medicine data
export const onSuggestionMedicine = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};
    //Call Update Token  - Get Token
    try {
      jwtAxios.post('v1/pateRxMgmt/suggestionMedListjwt', body).then((data) => {
        if (data.status === 200) {
          try {
            const jwtToken = data.data.body['token'];
            let bodytoken = {userId, erxReqId, jwtToken, userCode};
            jwtAxios
              .post('v1/pateRxMgmt/suggestionMedList', bodytoken)
              .then((data) => {
                if (data.data.body) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SUGGEST_MED,
                    payload: data.data.body?.medTotalList || [],
                  });
                  dispatch({
                    type: SUGGEST_MED_MESSAGE,
                    payload: data.data.body?.successMsg || '',
                  });
                  dispatch({
                    type: SUGGEST_MED_OPTION,
                    payload: data.data.body?.medoption || '',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onAddMedicineDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch({
              type: APPROVE_MEDICINE_MSG,
              payload: '',
            });
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onAddMedicineDetails',
                '0',
                err,
              ),
            );
          }
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

export const onNerSuggestionMedicine = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/nersuggestionMedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};
              jwtAxios
                .post('v1/pateRxMgmt/nersuggestionMedList', bodytoken)
                .then((data) => {
                  if (data.data.body) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: SUGGEST_MED_NER,
                      payload: data.data.body?.nermedTotalList || [],
                    });
                    dispatch({
                      type: MEDICINE_SUGGESTIONS,
                      payload: data?.data?.body?.medSuggestions && data?.data?.body?.medSuggestions != '{"{}"}' && data?.data?.body?.medSuggestions != '[{}]' && data?.data?.body?.medSuggestions != '[]'
                        ? JSON.parse(data.data.body?.medSuggestions)
                        : [],
                    });
                    dispatch({
                      type: SUGGEST_NER_MED_MESSAGE,
                      payload: data.data.body?.successMsg || '',
                    });
                    // dispatch({
                    //   type: SUGGEST_MED_OPTION,
                    //   payload: data.data.body?.medoption || '',
                    // });
                  if(data?.data?.body?.medSuggestions && data?.data?.body?.medSuggestions != '{"{}"}'  && data?.data?.body?.medSuggestions != '{"{}"}' && data?.data?.body?.medSuggestions != '[{}]' && data?.data?.body?.medSuggestions != '[]'){
                    localStorage.setItem(
                      erxReqId + '_nermedicines',
                      data?.data?.body?.medSuggestions,
                    );
                  }                 
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onAddnerMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch({
                type: APPROVE_MEDICINE_MSG,
                payload: '',
              });
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onAddnerMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Approve medicine data
export const onSuggestionPatient = ({userId, mobileno, userCode, uhid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, mobileno, userCode, uhid};
    //Call Update Token  - Get Token
    try {
      jwtAxios.post('v1/pateRxMgmt/suggestionPatListjwt', body).then((data) => {
        if (data.status === 200) {
          try {
            const jwtToken = data.data.body['token'];
            let bodytoken = {userId, mobileno, jwtToken, userCode, uhid};
            jwtAxios
              .post('v1/pateRxMgmt/suggestionPatList', bodytoken)
              .then((data) => {
                if (data.data.body) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SUGGEST_PAT,
                    payload: data.data.body
                      ? data.data.body.suggestionlist
                      : '',
                  });
                  dispatch({
                    type: SUGGEST_PAT_MESSAGE,
                    payload: data.data.body?.successMsg || '',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onSuggestionPatient',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch({
              type: APPROVE_MEDICINE_MSG,
              payload: '',
            });
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSuggestionPatient',
                '0',
                err,
              ),
            );
          }
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSuggestionPatient',
          '0',
          err,
        ),
      );
    }
  };
};

// On check duplicate Prescription
export const checkDuplicatePrescription = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/checkDuplicatePrescription', value)
        .then((data) => {
          if (data.data.body) {
            const getdata = data.data.body;
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: DUPLICATE_ERX_DATA,
              payload: getdata,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: APPROVE_MEDICINE_MSG,
        payload: '',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Approve medicine data
export const onApproveMedicine = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/approveMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/pateRxMgmt/approveMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: APPROVE_MEDICINE_MSG,
                      payload: 'Yes',
                    });
                  } else {
                    dispatch({
                      type: APPROVE_MEDICINE_MSG,
                      payload: 'No',
                    });
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({
                    type: APPROVE_MEDICINE_MSG,
                    payload: '',
                  });
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onAddMedicineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch({
                type: APPROVE_MEDICINE_MSG,
                payload: '',
              });
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onAddMedicineDetails',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// onCallback
export const onCallback = (value) => {
  // const history = useHistory();
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/callbackeRxjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/callbackeRx', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  if (value.status === 2) {
                    dispatch({
                      type: CALLBACK_MSG,
                      payload: 'Yes',
                    });
                  } else {
                    dispatch({
                      type: CALLBACK_MSG,
                      payload: 'No',
                    });
                  }
                } else {
                  dispatch({
                    type: CALLBACK_MSG,
                    payload: '',
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch({
                  type: CALLBACK_MSG,
                  payload: '',
                });
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onRejecteRx',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch({
              type: CALLBACK_MSG,
              payload: '',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onRejecteRx',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch({
            type: CALLBACK_MSG,
            payload: '',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onRejecteRx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch({
        type: CALLBACK_MSG,
        payload: '',
      });
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onRejecteRx', '0', err),
      );
    }
  };
};

//Update medicine order
export const onUpdateMedicineOrder = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call medicine order  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/medicineOrderjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/medicineOrder', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateMedicineOrder',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateMedicineOrder',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateMedicineOrder',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateMedicineOrder',
          '0',
          err,
        ),
      );
    }
  };
};
// Get vital master infor details
export const onGetVitalsMasterDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/pateRxMgmt/getVitalMastersjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/pateRxMgmt/getVitalMasters', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_MASTER_LIST_FOR_VITAL,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetVitalsMasterDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetVitalsMasterDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetVitalsMasterDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetVitalsMasterDetails',
          '0',
          err,
        ),
      );
    }
  };
};
// Get vital master infor details
export const onApproveStatus = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/pateRxMgmt/approvedVitalMastersjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/pateRxMgmt/approvedVitalMasters', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: APPROVE_STATUS_RESULT,
                    payload: 'success',
                  });
                } else {
                  dispatch({
                    type: APPROVE_STATUS_RESULT,
                    payload: 'failure',
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetVitalsMasterDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetVitalsMasterDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetVitalsMasterDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetVitalsMasterDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Delete master infor details
export const onDeleteVitalDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/pateRxMgmt/deleteVitalMastersjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/pateRxMgmt/deleteVitalMasters', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: DELETE_VITAL_STATUS_RESULT,
                    payload: 'success',
                  });
                } else {
                  dispatch({
                    type: DELETE_VITAL_STATUS_RESULT,
                    payload: 'failure',
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onDeleteVitalDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onDeleteVitalDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onDeleteVitalDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteVitalDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//Generate patient erx details
export const GenerateeRxHTML = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call generate html for erx
    try {
      jwtAxios
        .post('v1/pateRxMgmt/generateHTML', body)
        .then((data) => {
          dispatch({type: FETCH_SUCCESS});
          if (data && data.status && data.status === 200) {
            if (data.data.body) {
              dispatch({
                type: ERX_HTML_DATA,
                payload: data.data.body,
              });
            } else {
              dispatch({
                type: ERX_HTML_NO_DATA,
                payload: 'No record found',
              });
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: ERX_HTML_NO_DATA,
            payload: 'No record found',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GenerateeRxHTML',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: ERX_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'GenerateeRxHTML', '0', err),
      );
    }
  };
};

// Get InvestigationCategory  data
export const onGetInvestigationCategory = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/searchInvestigationCaetgoryjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/searchInvestigationCaetgory', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var alldata = [{uid: 0, category: 'All'}];
                  var arraydata = [...alldata, ...data.data.body];
                  dispatch({
                    type: INVESTIGATION_CATEGORY_DATA,
                    payload: data.data.body,
                  });
                  dispatch({
                    type: TAG_CATEGORY_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetInvestigationCategory',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetInvestigationCategory',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetInvestigationCategory',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetInvestigationCategory',
          '0',
          err,
        ),
      );
    }
  };
};

// onGeteRxReports

export const onGeteRxReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getReportDetailsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/getReportDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var reports = data.data.body.map(function (e) {
                    var reportdata =
                      e.reportTagId !== null &&
                      e.reportTagId !== undefined &&
                      e.reportTagId !== 0
                        ? {
                            uid: e.reportTagId,
                            category: e.reportCategory,
                            reportDate: e.investigationDate,
                          }
                        : {};
                    e.reportTagId =
                      e.reportTagId !== null && e.reportTagId !== undefined
                        ? e.reportTagId
                        : 0;
                    return {...e, reportTag: reportdata};
                  });
                  dispatch({
                    type: REPORT_DETAILS,
                    payload: reports,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeteRxReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxReports', '0', err),
      );
    }
  };
};

// onSaveeRxReports  data
export const onSaveeRxReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/saveReportDetailsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/saveReportDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  // console.log(data.data.body,"REPORT_DETAILS")
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: REPORT_VALIDATION,
                    payload: '',
                  });
                  // var reports= data.data.body.map(function(e)
                  // {

                  //   var reportdata=(e.reportTagId!==null &&e.reportTagId!==undefined && e.reportTagId!==0 )?{"uid":e.reportTagId,"category":e.reportCategory}:{};
                  //   e.reportTagId=(e.reportTagId!==null &&e.reportTagId!==undefined )?e.reportTagId:0
                  //   return {...e,reportTag:reportdata};
                  // })
                  // dispatch({
                  //   type: REPORT_DETAILS,
                  //   payload: reports,
                  // });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetInvestigationCategory',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetInvestigationCategory',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetInvestigationCategory',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetInvestigationCategory',
          '0',
          err,
        ),
      );
    }
  };
};

// Get ongetbucketandcaptcha  data
export const ongetbucketandcaptcha = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      // console.log("reachsnfjsdn")
      jwtAxios
        .post('v1/pateRxMgmt/ongetbucketandcaptcha')
        .then((data) => {
          if (data.data.status === 200) {
            // console.log(data.data.body)
            // dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: BUCKET_CAPTCHA,
              payload: data.data.body,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetbucketandcaptcha',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetbucketandcaptcha',
          '0',
          err,
        ),
      );
    }
  };
};

//Decrypt password
const decryptpassword = function ({data}) {
  var decipher = crypto.createDecipher(algorithm, password);
  var dec = decipher.update(data, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};

// Get ongetbucketandcaptcha  data
export const ongetEncryptbucketcaptcha = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getUploadPresCredentialsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getUploadPresCredentials', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  let data1 = data.data.body;
                  let res = {
                    type: decryptpassword({data: data1?.type}),
                    accessKey: decryptpassword({data: data1?.accessKey}),
                    secretAccessKey: decryptpassword({
                      data: data1?.secretAccessKey,
                    }),
                    bucketName: decryptpassword({data: data1?.bucketName}),
                    region: decryptpassword({data: data1?.region}),
                    filePath: decryptpassword({data: data1?.filePath}),
                    captchaKey: decryptpassword({data: data1?.captchaKey}),
                  };
                  dispatch({
                    type: BUCKET_CAPTCHA,
                    payload: res,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              });
          } catch (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetEncryptbucketcaptcha',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetEncryptbucketcaptcha',
          '0',
          err,
        ),
      );
    }
  };
};

//Update onUpdateReminderMedicineData
export const onUpdateReminderMedicineData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call onUpdateReminderMedicineData  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/updateReminderMedicineDatajwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/updateReminderMedicineData', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateReminderMedicineData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateReminderMedicineData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateReminderMedicineData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateReminderMedicineData',
          '0',
          err,
        ),
      );
    }
  };
};

//Update onGetSearchPatientData
export const onGetSearchPatientData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call onGetSearchPatientData  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/searchPatientDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/searchPatientData', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if (data.data.status === 200) {
                  const getdata = data.data.body.data;

                  dispatch({
                    type: SEARCH_PATIENT_LIST,
                    payload: getdata,
                  });
                  dispatch({
                    type: SEARCH_PATIENT_LIST_COUNT,
                    payload: data.data.body && data.data.body.totalCount,
                  });
                  dispatch({type: FETCH_SUCCESS});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'searchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'searchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'searchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'searchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};

// savePatientAppointmentDetails
export const savePatientAppointmentDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/insertAppointmentDetailsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/insertAppointmentDetails', value)
              .then((data) => {
                if (data.data.body) {
                  dispatch({
                    type: APPOINTMENT_SUCCESS,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: APPOINTMENT_SUCCESS_REFERESH,
                    payload: true,
                  });

                  return;
                } else {
                  dispatch({
                    type: APPOINTMENT_SUCCESS_REFERESH,
                    payload: false,
                  });
                  dispatch({
                    type: APPOINTMENT_SUCCESS,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                  return;
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'savePatientAppointmentDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'savePatientAppointmentDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'savePatientAppointmentDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'savePatientAppointmentDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//getAppointmentsJWt
export const onGetAppointments = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getPatientAppointmentDetailsJwt', value)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = value;
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/reportDtls/getPatientAppointmentDetails', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: PATIENT_APPOINTMENT_COUNT,
                      payload: data.data.body.totalCount,
                    });
                    dispatch({
                      type: PATIENT_APPOINTMENT_LIST,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetAppointments',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetAppointments',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAppointments',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetAppointments',
          '0',
          err,
        ),
      );
    }
  };
};

//Get erxList report Data
export const onGetErxListReportData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterAgent,
  filterVendor,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterAgent,
      filterVendor,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxListReportJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                status,
                offset,
                limit,
                jwtToken,
              };
              jwtAxios
                .post('v1/pateRxMgmt/geteRxListReport', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const erxList = data.data.body.data;
                    dispatch({
                      type: ERX_LIST_REPORT,
                      payload: erxList,
                    });
                    dispatch({
                      type: ERX_LIST_REPORT_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetErxListReportData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetErxListReportData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetErxListReportData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetErxListReportData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get erxList report Data
export const onGetErxOldListReportData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterAgent,
  filterVendor,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterAgent,
      filterVendor,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxOldListReportJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                status,
                offset,
                limit,
                jwtToken,
              };
              jwtAxios
                .post('v1/pateRxMgmt/geteRxOldListReport', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const erxList = data.data.body.data;
                    dispatch({
                      type: ERX_OLD_LIST_REPORT,
                      payload: erxList,
                    });
                    dispatch({
                      type: ERX_LIST_REPORT_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetErxOldListReportData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetErxOldListReportData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetErxOldListReportData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetErxOldListReportData',
          '0',
          err,
        ),
      );
    }
  };
};

// Send Prescription
export const sendPrescription = ({
  userCode,
  erxId,
  userId,
  status,
  roleId,
  erxStatus,
  erxData,
  clientName,
  clientId,
  editPrescription,
  session_id,
}) => {
  return (dispatch) => {
    //Call SendToReview Token  - Get Token
    const value = {
      userCode,
      erxId,
      userId,
      status,
      erxStatus,
      clientId,
      editPrescription,
      session_id,
    };
    try {
      jwtAxios
        .post('v1/pateRxMgmt/sendPrescriptionJwt', value)
        .then((data) => {
          try {
            let value1 = {};
            value1['jwtToken'] = data.data.body['token'];
            value1['erxData'] = erxData;
            jwtAxios
              .post('v1/pateRxMgmt/sendPrescription', value1)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEND_SUCCESS_MSG,
                    payload: 'eRx has been successfully sent to ' + clientName,
                  });

                  //Get pending list data from api
                  if (userCode != null) {
                    dispatch({
                      type: DASHBOARD_PENDING_DATA,
                      payload: [],
                    });
                    dispatch({
                      type: DASHBOARD_PENDING_COUNT,
                      payload: 0,
                    });
                    dispatch(
                      onGetDashboardPendingListData({
                        userCode: userCode,
                        userId: '',
                        status: '0',
                        offset: 0,
                        limit: 10,
                        filtercheckInStartDate: '',
                        filtercheckInEndDate: '',
                        filterfullfillmentStartDate: '',
                        filterfullfillmentEndDate: '',
                        filterpatName: '',
                        filterMedia: '',
                        filterService: [],
                        filterVendor: '',
                        session_id: session_id,
                      }),
                    );
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'sendPrescription',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendPrescription',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendPrescription',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendPrescription',
          '0',
          err,
        ),
      );
    }
  };
};

// // Send Prescription
export const sendAmazonPrescription = ({
  userCode,
  erxReqId,
  userId,
  sessionId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call SendToReview Token  - Get Token
    const value = {
      userCode,
      erxReqId,
      userId,
      sessionId,
    };
    try {
      jwtAxios
        .post('v1/teleConsultant/sendIngressSchemaToAmazon', value)
        .then((data) => {
          try {
            dispatch({type: FETCH_SUCCESS});
            if (data.status === 200) {
              var content = data?.data?.message || '';
              if (content.includes('Error')) {
                var message = data?.data?.message || '';
                dispatch({
                  type: SEND_AMAZON_SUCCESS_MSG,
                  payload: message,
                });
              } else {
                dispatch({
                  type: SEND_AMAZON_SUCCESS_MSG,
                  payload: 'eRx has been successfully sent to Amazon',
                });
                let passdata = {
                  userCode: value.userCode,
                  userId: '',
                  status: '4',
                  offset: 0,
                  limit: 25,
                  filtercheckInStartDate: localStorage.getItem(
                    'AmazonFilterFromDate',
                  )
                    ? localStorage.getItem('AmazonFilterFromDate')
                    : '',
                  filtercheckInEndDate: localStorage.getItem(
                    'AmazonFilterToDate',
                  )
                    ? localStorage.getItem('AmazonFilterToDate')
                    : '',
                  filterfullfillmentStartDate: '',
                  filterfullfillmentEndDate: '',
                  filterpatName: '',
                  filterMedia: '',
                  filterService: [],
                  filterVendor: '',
                  session_id: value.sessionId,
                  retryTag: true,
                };
                dispatch(onGetAmazonDashboardSendFailureListData(passdata));
              }
            }
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendAmazonPrescription',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendAmazonPrescription',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendAmazonPrescription',
          '0',
          err,
        ),
      );
    }
  };
};

// // Send Prescription
export const sendAdminAmazonPrescription = ({
  userCode,
  erxReqId,
  userId,
  sessionId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call SendToReview Token  - Get Token
    const value = {
      userCode,
      erxReqId,
      userId,
      sessionId,
    };
    try {
      jwtAxios
        .post('v1/teleConsultant/adminSendIngressToAmazonJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/adminSendIngressToAmazon', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if (data.status === 200) {
                  dispatch({
                    type: SEND_AMAZON_SUCCESS_MSG,
                    payload: 'eRx has been successfully sent to Amazon',
                  });
                  let passdata = {
                    userCode: value.userCode,
                    userId: '',
                    status: '4',
                    offset: 0,
                    limit: 25,
                    filtercheckInStartDate: localStorage.getItem(
                      'AmazonFilterFromDate',
                    )
                      ? localStorage.getItem('AmazonFilterFromDate')
                      : '',
                    filtercheckInEndDate: localStorage.getItem(
                      'AmazonFilterToDate',
                    )
                      ? localStorage.getItem('AmazonFilterToDate')
                      : '',
                    filterfullfillmentStartDate: '',
                    filterfullfillmentEndDate: '',
                    filterpatName: '',
                    filterMedia: '',
                    filterService: [],
                    filterVendor: '',
                    session_id: value.sessionId,
                    retryTag: true,
                  };
                  dispatch(onGetAmazonDashboardSendFailureListData(passdata));
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendAdminAmazonPrescription',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendAdminAmazonPrescription',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendAdminAmazonPrescription',
          '0',
          err,
        ),
      );
    }
  };
};

// // Send Prescription
export const amazonRejectRetry = ({userCode, erxId, userId, sessionId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call SendToReview Token  - Get Token
    const value = {
      userCode,
      erxId,
      userId,
    };
    try {
      jwtAxios
        .post('v1/teleConsultant/retryRejecteRx', value)
        .then((data) => {
          try {
            if (data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: SEND_AMAZON_SUCCESS_MSG,
                payload: 'Submit Successfully',
              });
              let passdata = {
                userCode: value.userCode,
                userId: '',
                status: '4',
                offset: 0,
                limit: 25,
                filtercheckInStartDate: localStorage.getItem(
                  'AmazonFilterFromDate',
                )
                  ? localStorage.getItem('AmazonFilterFromDate')
                  : '',
                filtercheckInEndDate: localStorage.getItem('AmazonFilterToDate')
                  ? localStorage.getItem('AmazonFilterToDate')
                  : '',
                filterfullfillmentStartDate: '',
                filterfullfillmentEndDate: '',
                filterpatName: '',
                filterMedia: '',
                filterService: [],
                filterVendor: '',
                session_id: sessionId,
                retryTag: true,
              };
              dispatch(onGetAmazonDashboardSendFailureListData(passdata));
            }
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendAmazonPrescription',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendAmazonPrescription',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendAmazonPrescription',
          '0',
          err,
        ),
      );
    }
  };
};

// // Send Prescription
export const adminamazonRejectRetry = ({
  userCode,
  erxId,
  userId,
  sessionId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call SendToReview Token  - Get Token
    const value = {
      userCode,
      erxId,
      userId,
    };
    try {
      jwtAxios
        .post('v1/teleConsultant/adminRetryRejecteRxJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/adminRetryRejecteRx', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEND_AMAZON_SUCCESS_MSG,
                    payload: 'Submit Successfully',
                  });
                  let passdata = {
                    userCode: value.userCode,
                    userId: '',
                    status: '4',
                    offset: 0,
                    limit: 25,
                    filtercheckInStartDate: localStorage.getItem(
                      'AmazonFilterFromDate',
                    )
                      ? localStorage.getItem('AmazonFilterFromDate')
                      : '',
                    filtercheckInEndDate: localStorage.getItem(
                      'AmazonFilterToDate',
                    )
                      ? localStorage.getItem('AmazonFilterToDate')
                      : '',
                    filterfullfillmentStartDate: '',
                    filterfullfillmentEndDate: '',
                    filterpatName: '',
                    filterMedia: '',
                    filterService: [],
                    filterVendor: '',
                    session_id: sessionId,
                    retryTag: true,
                  };
                  dispatch(onGetAmazonDashboardSendFailureListData(passdata));
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendAmazonPrescription',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendAmazonPrescription',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendAmazonPrescription',
          '0',
          err,
        ),
      );
    }
  };
};

// updatePatientAppointmentDetails
export const updatePatientAppointmentDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/updateAppointmentDetailsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/updateAppointmentDetails', value)
              .then((data) => {
                if (data.data.body) {
                  dispatch({
                    type: APPOINTMENT_SUCCESS,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: APPOINTMENT_SUCCESS_REFERESH,
                    payload: true,
                  });

                  return;
                } else {
                  dispatch({
                    type: APPOINTMENT_SUCCESS_REFERESH,
                    payload: false,
                  });
                  dispatch({
                    type: APPOINTMENT_SUCCESS,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                  return;
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'savePatientAppointmentDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'savePatientAppointmentDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'savePatientAppointmentDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'savePatientAppointmentDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//getClientDashboardDetails
export const getClientDashboardDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getClientDashboardDetailsJwt', value)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {};
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/reportDtls/getClientDashboardDetails', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: CLIENT_DASHBOARD_DETAILS,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getClientDashboardDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getClientDashboardDetails',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getClientDashboardDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getClientDashboardDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//getbillingDashboard
export const getbillingDashboard = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getbillingDashboardJwt', value)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {};
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/reportDtls/getbillingDashboard', bodytoken)
                .then((data) => {
                  console.log(data.data.body, 'body result');
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    console.log();
                    const getdata = data.data.body;
                    dispatch({
                      type: CLIENT_BILLING_DETAILS,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getbillingDashboard',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getbillingDashboard',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getbillingDashboard',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getbillingDashboard',
          '0',
          err,
        ),
      );
    }
  };
};

//Get ClientErxList
export const onGetClientErxList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/reportDtls/getClientErxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/reportDtls/getClientErxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const erxList = data.data.body.data;
                    dispatch({
                      type: CLIENT_ERX_LIST,
                      payload: erxList,
                    });
                    dispatch({
                      type: CLIENT_ERX_TOTAL_FEES,
                      payload:
                        (data.data.body.data &&
                          data.data.body.data.total_fees) ||
                        0,
                    });
                    dispatch({
                      type: CLIENT_ERX_LIST_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetClientErxList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetClientErxList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetClientErxList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetClientErxList',
          '0',
          err,
        ),
      );
    }
  };
};
//Update onGetSearchPatientData
export const onGetClientSearchData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call onGetSearchPatientData  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/searchClientPatientDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/searchClientPatientData', value)
              .then((data) => {
                if (data.data.status === 200) {
                  const getdata = data.data.body.data;

                  dispatch({
                    type: CLIENT_SEARCH_LIST,
                    payload: getdata,
                  });
                  dispatch({
                    type: CLIENT_SEARCH_LIST_COUNT,
                    payload: data.data.body && data.data.body.totalCount,
                  });
                  dispatch({type: FETCH_SUCCESS});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'searchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'searchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'searchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'searchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};
//Get ClientErxList
export const onGetExcelErxList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/reportDtls/getClientErxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/reportDtls/getClientErxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    const erxList = data.data.body.data;
                    dispatch({
                      type: CLIENT_EXCEL_LIST,
                      payload: erxList,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetClientErxList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetClientErxList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetClientErxList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetClientErxList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetTime = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios.get('v1/pateRxMgmt/getcrmotpvalidy').then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: REMEMBER_ME_DAYS,
            payload:
              data.data.body.rememberMeDays &&
              data.data.body.rememberMeDays.description
                ? parseInt(data.data.body.rememberMeDays.description)
                : 0,
          });
          dispatch({
            type: OTP_TIME,
            payload:
              data.data.body.otpValidity &&
              data.data.body.otpValidity.description
                ? data.data.body.otpValidity.description
                : 0,
          });
          dispatch({
            type: AMAZON_CONSULT_TIMING,
            payload: data?.data?.body?.teleOperatorValidity?.consultationTiming
              ? data.data.body.teleOperatorValidity.consultationTiming
              : 0,
          });
          dispatch({
            type: AMAZON_IDLE_TIME,
            payload: data?.data?.body?.teleOperatorValidity?.idleTimeInterval
              ? data.data.body.teleOperatorValidity.idleTimeInterval
              : 0,
          });
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetTime', '0', err),
      );
    }
  };
};

// get investigation suggestions
export const onSuggestionInvestigation = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/suggestionInvestigation', body)
        .then((data) => {
          if (data.data.body) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SUGGEST_INVESTIGATION,
              payload: data.data.body?.investigationList || [],
            });
            dispatch({
              type: SUGGEST_INVES_MSG,
              payload: data.data.body?.successMsg || '',
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// get onGetEntity
export const onGetEntity = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/searchOcrData', body)
        .then((data) => {
          if (data.data.body) {
            console.log(data.data.body, 'entityMappingentityMapping');
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: ENTITY_MAPPING,
              payload: data.data.body?.ocrData || [],
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetVitalInfo = ({userId, erxId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxId, userCode};
    //Call Update Token  - Get Token
    console.log(erxId, 'erxId');
    try {
      jwtAxios.post('v1/pateRxMgmt/searchVitalInfojwt', body).then((data) => {
        if (data.status === 200) {
          try {
            const jwtToken = data.data.body['token'];
            let bodytoken = {userId, erxId, jwtToken, userCode};
            jwtAxios
              .post('v1/pateRxMgmt/searchVitalInfo', bodytoken)
              .then((data) => {
                if (data.data.body) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_NER_INVESTIGATION,
                    payload: data.data.body?.invesList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_SYMPTOMS,
                    payload: data.data.body?.sympList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_PROCEDURE,
                    payload: data.data.body?.procedList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_DISEASES,
                    payload: data.data.body?.diseaseList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_DIAGNOSIS,
                    payload: data.data.body?.diagnosisList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_ALLERGY,
                    payload: data.data.body?.allergyList || [],
                  });

                  // dispatch({
                  //   type: SUGGEST_NER_MED_MESSAGE,
                  //   payload: data.data.body?.successMsg || '',
                  // });
                  // dispatch({
                  //   type: SUGGEST_MED_OPTION,
                  //   payload: data.data.body?.medoption || '',
                  // });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetVitalInfo',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch({
              type: APPROVE_MEDICINE_MSG,
              payload: '',
            });
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetVitalInfo',
                '0',
                err,
              ),
            );
          }
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetVitalInfo', '0', err),
      );
    }
  };
};

export const onGetNerHospitalSuggestions = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get the data
      try {
        jwtAxios
          .post('v1/doctorDtls/searchNerHospitalSuggestionsJwt', value)
          .then((data) => {
            if (data.status === 200) {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/doctorDtls/searchNerHospitalSuggestions', value)
                .then((data) => {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_NER_HOSPITALS,
                    payload: data.data?.body?.suggestionnerhospitalList,
                  });
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetNerHospitalSuggestions',
                      '0',
                      error,
                    ),
                  );
                });
            }
          });
      } catch (err) {
        dispatch({
          type: APPROVE_MEDICINE_MSG,
          payload: '',
        });
      }
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetNerHospitalSuggestions',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetVitalSuggestions = ({userId, erxId, userCode, doctorId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxId, userCode, doctorId};
    //Call Update Token  - Get Token
    console.log(erxId, 'erxId');
    try {
      jwtAxios.post('v1/pateRxMgmt/vitalsuggestionJwt', body).then((data) => {
        if (data.status === 200) {
          try {
            const jwtToken = data.data.body['token'];
            let bodytoken = {userId, erxId, jwtToken, userCode, doctorId};
            jwtAxios
              .post('v1/pateRxMgmt/vitalsuggestion', bodytoken)
              .then((data) => {
                if (data.data.body) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_NER_INVESTIGATION,
                    payload: data.data.body?.investigationList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_SYMPTOMS,
                    payload: data.data.body?.symptomslist || [],
                  });
                  dispatch({
                    type: SEARCH_NER_PROCEDURE,
                    payload: data.data.body?.proceduresList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_DISEASES,
                    payload: data.data.body?.diseaseList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_DIAGNOSIS,
                    payload: data.data.body?.diagnosisList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_ALLERGY,
                    payload: data.data.body?.allergyList || [],
                  });

                  dispatch({
                    type: SUGGEST_NER_MED_MESSAGE,
                    payload: data.data.body?.successMsg || '',
                  });
                  dispatch({
                    type: VITAL_SUGGESTIONS,
                    payload: data.data.body?.vitallen || [],
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetVitalInfo',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch({
              type: APPROVE_MEDICINE_MSG,
              payload: '',
            });
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetVitalInfo',
                '0',
                err,
              ),
            );
          }
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetVitalInfo', '0', err),
      );
    }
  };
};

export const onGetIPDVitalSuggestions = ({
  userId,
  erxId,
  userCode,
  doctorId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxId, userCode, doctorId};
    //Call Update Token  - Get Token
    console.log(erxId, 'erxId');
    try {
      jwtAxios.post('v1/ipdPres/vitalIPDsuggestionJwt', body).then((data) => {
        if (data.status === 200) {
          try {
            const jwtToken = data.data.body['token'];
            let bodytoken = {userId, erxId, jwtToken, userCode, doctorId};
            jwtAxios
              .post('v1/ipdPres/vitalIPDsuggestion', bodytoken)
              .then((data) => {
                if (data.data.body) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_NER_INVESTIGATION,
                    payload: data.data.body?.investigationList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_SYMPTOMS,
                    payload: data.data.body?.symptomslist || [],
                  });
                  dispatch({
                    type: SEARCH_NER_PROCEDURE,
                    payload: data.data.body?.proceduresList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_DISEASES,
                    payload: data.data.body?.diseaseList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_DIAGNOSIS,
                    payload: data.data.body?.diagnosisList || [],
                  });
                  dispatch({
                    type: SEARCH_NER_ALLERGY,
                    payload: data.data.body?.allergyList || [],
                  });

                  dispatch({
                    type: SUGGEST_NER_MED_MESSAGE,
                    payload: data.data.body?.successMsg || '',
                  });
                  dispatch({
                    type: VITAL_SUGGESTIONS,
                    payload: data.data.body?.vitallen || [],
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetIPDVitalSuggestions',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch({
              type: APPROVE_MEDICINE_MSG,
              payload: '',
            });
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetIPDVitalSuggestions',
                '0',
                err,
              ),
            );
          }
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetIPDVitalSuggestions',
          '0',
          err,
        ),
      );
    }
  };
};

export const onAutomarkcropCoordinates = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get the data
      jwtAxios
        .post('v1/pateRxMgmt/generateCropCoordinates', body)
        .then((data) => {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: AUTOMARKCROP_COORDINATES,
            payload: data.data.body,
          });
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAutomarkcropCoordinates',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAutomarkcropCoordinates',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetIPDPendingPatInfn = ({userId, userCode, patId, erxid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, patId, erxid};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/getIPDpatientjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, userCode, patId, erxid, jwtToken};
              jwtAxios
                .post('v1/ipdPres/getIPDpatient', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata =
                      data?.data?.body?.['patientDetails']?.[0] || [];
                    const getDeptdata =
                      data?.data?.body['departmentList'] || [];
                    dispatch({
                      type: DASHBOARD_IPDPENDING_PATIENT_DATA,
                      payload: getdata,
                    });
                    dispatch({
                      type: DASHBOARD_DEPARTMENT,
                      payload: getDeptdata,
                    });
                    dispatch({
                      type: IPD_DEPT_DET,
                      payload: data?.data?.body?.['clientDeptList'] || '',
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch({
                    type: DASHBOARD_IPDPENDING_PATIENT_DATA,
                    payload: {},
                  });
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetIPDPendingPatInfn',
                      body.userCode,
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetIPDPendingPatInfn',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetIPDPendingPatInfn',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetIPDPendingPatInfn',
          body.userCode,
          err,
        ),
      );
    }
  };
};

export const onGetregisteredClients = ({userCode, clientId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userCode, clientId};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/patientDtls/onGetRegisteredClients', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            dispatch({type: FETCH_SUCCESS});
            console.log(data, 'data onGetRegisteredClients');
            dispatch({
              type: GET_REGISTERED_CLIENTS,
              payload: data?.data?.body?.clientList,
            });
            dispatch({
              type: GET_REGISTERED_CLIENTS_DOCTORS,
              payload: data?.data?.body?.doctorsList,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetIPDPendingPatInfn',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetIPDPendingPatInfn',
          body.userCode,
          err,
        ),
      );
    }
  };
};

export const onGetIPDeRxReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/ipdPres/getIPDReportDetailsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/getIPDReportDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var reports = data.data.body.map(function (e) {
                    var reportdata =
                      e.reportTagId !== null &&
                      e.reportTagId !== undefined &&
                      e.reportTagId !== 0
                        ? {
                            uid: e.reportTagId,
                            category: e.reportCategory,
                            reportDate: e.investigationDate,
                          }
                        : {};
                    e.reportTagId =
                      e.reportTagId !== null && e.reportTagId !== undefined
                        ? e.reportTagId
                        : 0;
                    return {...e, reportTag: reportdata};
                  });
                  dispatch({
                    type: REPORT_DETAILS,
                    payload: reports,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetIPDeRxReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetIPDeRxReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetIPDeRxReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetIPDeRxReports',
          '0',
          err,
        ),
      );
    }
  };
};

// onSaveeRxReports  data
export const onSaveIPDeRxReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/ipdPres/saveIPDReportDetailsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/saveIPDReportDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  // console.log(data.data.body,"REPORT_DETAILS")
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: REPORT_VALIDATION,
                    payload: '',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onSaveIPDeRxReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSaveIPDeRxReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSaveIPDeRxReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSaveIPDeRxReports',
          '0',
          err,
        ),
      );
    }
  };
};

export const checkIPDDuplicatePrescription = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/checkIpdDuplicatePrescription', value)
        .then((data) => {
          if (data.data.body) {
            const getdata = data.data.body;
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: DUPLICATE_ERX_DATA,
              payload: getdata,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: APPROVE_MEDICINE_MSG,
        payload: '',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

export const sendIpdPrescriptions = (passdata) => {
  return (dispatch) => {
    //Call inprogress list Token  - Get Token
    localStorage.setItem('pdfsent', false);
    try {
      jwtAxios
        .post('v1/ipdPres/erxIPDPdfGeneration', passdata)
        .then((res) => {
          var snoozeres = res.data.body.snooze ? res.data.body.snooze[0] : '';
          if (passdata.erxStatus === 'Send to Patient') {
            dispatch({
              type: SEND_SUCCESS_MSG,
              payload: 'eRx has been successfully sent to patient',
            });
          } else {
            dispatch({
              type: SEND_SUCCESS_MSG,
              payload: 'eRx has been successfully sent to L2 Operator',
            });
          }
          if (snoozeres) {
            localStorage.setItem('snoozeTime', JSON.stringify(snoozeres));
          }
          localStorage.setItem('pdfsent', true);
          //Get pending list data from api
          if (passdata.userCode != null) {
            dispatch({
              type: DASHBOARD_PENDING_DATA,
              payload: [],
            });
            dispatch({
              type: DASHBOARD_PENDING_COUNT,
              payload: 0,
            });
            dispatch(
              onGetDashboardPendingListData({
                userCode: passdata.userCode,
                userId: '',
                status: '0',
                offset: 0,
                limit: 10,
                filtercheckInStartDate: '',
                filtercheckInEndDate: '',
                filterfullfillmentStartDate: '',
                filterfullfillmentEndDate: '',
                filterpatName: '',
                filterMedia: '',
                filterService: [],
                filterVendor: '',
                session_id: passdata.session_id,
              }),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendIpdPrescriptions',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendIpdPrescriptions',
          '0',
          err,
        ),
      );
    }
  };
};

export const getApprovedlist = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/ipdPres/getApprovedVitalsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/getApprovedVitals', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_APPROVED_VITALS,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getApprovedlist',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getApprovedlist',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getApprovedlist',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'getApprovedlist', '0', err),
      );
    }
  };
};

const replaceJsonInArray = (erxid, newObj, oldList) => {
  const pendingList = oldList;

  if (pendingList && pendingList.length > 0) {
    const index = pendingList.findIndex((item) => item.erxId === erxid);
    if (index !== -1) {
      const newData = [...pendingList];
      newData[index] = newObj[0];
      return newData;
    }
  } else {
    if (pendingList && pendingList.length == 0) {
      return 0;
    }
  }
};

export const onAddNewVitals = (value, oldList) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    console.log(value, oldList, 'oldList');
    try {
      jwtAxios
        .post('v1/ipdPres/approveVitalsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/approveVitals', value)
              .then((data) => {
                if (data.data.status === 200) {
                  console.log(data.data.body, 'REPORT_DETAILS');
                  dispatch({type: FETCH_SUCCESS});
                  let result = data.data.body;
                  if (result?.status) {
                    let msg = result?.status;
                    if (msg && msg.includes('1')) {
                      dispatch({
                        type: ADMIN_APPROVE_SUCC,
                        payload: 'Prescription Approved Successfully',
                      });
                      let passData = {
                        userCode: value.userCode,
                        userId: value.userId,
                      };
                      dispatch(onGetAdminPendingPres(passData));
                    } else {
                      dispatch({
                        type: ADMIN_APPROVE_SUCC,
                        payload:
                          value.process == 'Reject'
                            ? value.type + ' Rejected Successfully'
                            : value.process == 'Update'
                            ? value.type + ' Updated Successfully'
                            : value.type + ' Approved Successfully',
                      });
                      if (result.approveData && result.approveData.length > 0) {
                        let list = replaceJsonInArray(
                          value.erxId,
                          result.approveData,
                          oldList,
                        );
                        dispatch({
                          type: ADMIN_PENDING_LIST,
                          payload: list,
                        });
                      }
                    }
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onAddNewVitals',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onAddNewVitals',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddNewVitals',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onAddNewVitals', '0', err),
      );
    }
  };
};

export const onGetAdminPendingPres = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/ipdPres/getApprovalPendingListJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/getApprovalPendingList', value)
              .then((data) => {
                if (data.data.status === 200) {
                  console.log(data.data.body, 'REPORT_DETAILS');
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: ADMIN_PENDING_LIST,
                    payload: data?.data?.body?.list,
                  });
                  dispatch({
                    type: ADMIN_PENDING_COUNT,
                    payload: data?.data?.body?.totalCount,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetAdminPendingPres',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetAdminPendingPres',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAdminPendingPres',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetAdminPendingPres',
          '0',
          err,
        ),
      );
    }
  };
};

export const getVitalMasterData = (value, oldList) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    console.log(value, oldList, 'oldList');
    try {
      jwtAxios
        .post('v1/ipdPres/getVitalDetailsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/getVitalDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  console.log(data.data.body, 'REPORT_DETAILS');
                  dispatch({type: FETCH_SUCCESS});
                  let result = data.data.body;
                  if (result && result.length > 0) {
                    dispatch({
                      type: ADMIN_MASTER_DATA,
                      payload: result,
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onAddNewVitals',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onAddNewVitals',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddNewVitals',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onAddNewVitals', '0', err),
      );
    }
  };
};

export const checkduplicateIpdvital = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/ipdPres/checkIpdVitalDuplicateJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/checkIpdVitalDuplicate', value)
              .then((data) => {
                if (data.data.status === 200) {
                  console.log(data.data.body, 'REPORT_DETAILS');
                  dispatch({type: FETCH_SUCCESS});
                  let result = data.data.body;
                  console.log(result, 'result');
                  dispatch({
                    type: VITAL_DUPLICATE_CHECK,
                    payload: result,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'checkduplicateIpdvital',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'checkduplicateIpdvital',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'checkduplicateIpdvital',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'checkduplicateIpdvital',
          '0',
          err,
        ),
      );
    }
  };
};

// Call Reasons
export const ongetCallAttempts = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getTeleconsultationCallReasonsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getTeleconsultationCallReasons', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_CALL_REASONS,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'ongetCallAttempts',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'ongetCallAttempts',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetCallAttempts',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetCallAttempts',
          '0',
          err,
        ),
      );
    }
  };
};

//getting pending approval prescriptions count
export const onGetAdminPendingPresCount = (value) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/ipdPres/getIPDPendingApprovaljwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/ipdPres/getIPDPendingApproval', value)
              .then((data) => {
                if (data.data.status === 200) {
                  // dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: ADMIN_PENDING_APPROVALS_LIST,
                    payload: data?.data?.body?.list,
                  });
                  dispatch({
                    type: ADMIN_PENDING_APPROVALS,
                    payload: data?.data?.body?.totalCount,
                  });
                } else {
                  dispatch({
                    type: ADMIN_PENDING_APPROVALS,
                    payload: 0,
                  });
                }
              })
              .catch((error) => {
                dispatch({
                  type: ADMIN_PENDING_APPROVALS,
                  payload: 0,
                });
              });
          } catch (err) {
            dispatch({
              type: ADMIN_PENDING_APPROVALS,
              payload: 0,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAdminPendingPres',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetAdminPendingPres',
          '0',
          err,
        ),
      );
    }
  };
};

//getting High priority approval count
export const getHighPriorityAlert = (value) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getHighPriorityAlertJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getHighPriorityAlert', value)
              .then((data) => {
                if (data.status === 200) {
                  // dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: HIGHPRIORITYCOUNT,
                    payload: data?.data?.body?.data || {},
                  });
                } else {
                  dispatch({
                    type: HIGHPRIORITYCOUNT,
                    payload: {},
                  });
                }
              })
              .catch((error) => {
                dispatch({
                  type: HIGHPRIORITYCOUNT,
                  payload: {},
                });
              });
          } catch (err) {
            dispatch({
              type: HIGHPRIORITYCOUNT,
              payload: {},
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getHighPriorityAlert',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getHighPriorityAlertJwt',
          '0',
          err,
        ),
      );
    }
  };
};

//get erxNotes
export const onGetDoctorNotesTestNames = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getDoctorNotesTest', value)
        .then((data) => {
          try {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: DOCTOR_NOTES_TEST_NAMES,
              payload: data.data.body,
            });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDoctorNotesTestNames',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorNotesTestNames',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDoctorNotesTestNames',
          '0',
          err,
        ),
      );
    }
  };
};

//get erxNotes
export const retryChatGptOCR = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/retryOCR', value)
        .then((data) => {
          try {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: RETRY_OCR,
              payload: data.data.body,
            });
            console.log(value, 'value');

            dispatch(
              onGeteRxInfn({
                userId: value?.erxId,
                erxReqId: value?.erxId?.toString(),
                userCode: value?.userCode,
                userId: value?.userId,
              }),
            );
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', err),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', error),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', err),
      );
    }
  };
};

export const retryChatGptOCRIPD = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/retryOCR', value)
        .then((data) => {
          try {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: RETRY_OCR,
              payload: data.data.body,
            });
            console.log(value, 'value');

            dispatch(
              onGetIPDeRxInfn({
                userId: value?.erxId,
                erxReqId: value?.erxId?.toString(),
                userCode: value?.userCode,
                userId: value?.userId,
              }),
            );
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', err),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', error),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', err),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetamazonCallbackListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
  filterMobileNo,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      filterMobileNo,
      session_id,
    };

    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazoninprogreseRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/teleConsultant/getAmazoninprogreseRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatainprogres = data.data.body.data;
                    dispatch({
                      type: AMAZON_CALLBACK_DATA,
                      payload: getdatainprogres,
                    });
                    dispatch({
                      type: TELE_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: AMAZON_CALLBACK_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetamazonCallbackListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetamazonCallbackListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetamazonCallbackListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetamazonCallbackListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetamazonConFailListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
  filterMobileNo,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      filterMobileNo,
      session_id,
    };

    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/teleConsultant/getAmazoninprogreseRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/teleConsultant/getAmazoninprogreseRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatainprogres = data.data.body.data;
                    dispatch({
                      type: AMAZON_CONFAIL_DATA,
                      payload: getdatainprogres,
                    });
                    dispatch({
                      type: TELE_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: AMAZON_CONFAIL_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetamazonConFailListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetamazonConFailListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetamazonConFailListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetamazonConFailListData',
          '0',
          err,
        ),
      );
    }
  };
};

// Add eRx Medicine details
export const onGetVaccinationDetails = (value) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/vaccination/getVaccinationMaster', value)
        .then((data) => {
          if (data.status === 200) {
            dispatch({
              type: GET_VACCINATION_MASTER,
              payload: data?.data?.body,
            });
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Update Patient details
export const saveMarkedVaccine = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/vaccination/saveVaccineDtlsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // update patient info
            jwtAxios
              .post('v1/vaccination/saveVaccineDtls', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  console.log(data.data, 'data.datadata.data');
                  dispatch({
                    type: VACCINATION_SAVE_MSG,
                    payload: data?.data?.body?.msg,
                  });

                  dispatch({
                    type: VACCINATION_SAVE_DATA,
                    payload: data?.data?.body?.ageLabel,
                  });
                  //
                  dispatch({
                    type: VACCINATION_DONE_DATE,
                    payload: data?.data?.body?.givenDate,
                  });

                  dispatch(
                    onGetVaccinationDetails({
                      userCode: value.userCode,
                      erxId: value.erxId,
                    }),
                  );
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdatePatientDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdatePatientDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdatePatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdatePatientDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Operator name
export const onGetOperatorName = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/showOperatorDetailsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/showOperatorDetails', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_OPERATOR_NAME,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetOperatorName',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetOperatorName',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOperatorName',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetOperatorName',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetteleOperatorName = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/teleConsultant/showOperatorDetailsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/showOperatorDetails', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  if (value?.erxId?.includes('tc')) {
                    dispatch({
                      type: GET_TELE_OPERATOR_NAME,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: GET_DIGI_OPERATOR_NAME,
                      payload: data.data.body,
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetteleOperatorName',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetteleOperatorName',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetteleOperatorName',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetteleOperatorName',
          '0',
          err,
        ),
      );
    }
  };
};

export const getClientDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/vendor/getClientDetailsjwt', value)
        .then((data) => {
          try {
            let json = {jwtToken: data.data.body['token']};

            jwtAxios
              .post('v1/vendor/getClientDetails', json)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_CLIENT_DETAILS,
                    payload: data.data.body.list,
                  });
                  let locMedList=data?.data?.body?.list?.mediaList ? data?.data?.body?.list?.mediaList:[];
                  locMedList.push({mediaId: 0, mediaName: 'All'});
                  dispatch({
                    type: CLIENT_MEDIA_lIST,
                    payload: locMedList ? locMedList:[] ,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getClientDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getClientDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getClientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getClientDetails',
          '0',
          err,
        ),
      );
    }
  };
};

export const sendDriveAuthentication = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call generate html for erx
    try {
      jwtAxios
        .post('v1/mrdUser/MRDdriveAuthenticationRes', body)
        .then((data) => {
          dispatch({type: FETCH_SUCCESS});
          if (data && data.status && data.status === 200) {
            
          }
        })
        .catch((error) => {
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendDriveAuthentication',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'sendDriveAuthentication', '0', err),
      );
    }
  };
};